import axios from 'axios';
import { PatchObject } from 'json-patch-gen';
import AccountDto from '../dtos/AccountDto';
import ChangePasswordDto from '../dtos/ChangePasswordDto';
import SecondFactorDto from '../dtos/SecondFactorDto';
import { useI18n } from 'vue-i18n';
import AccountClientMock from './mocks/AccountClientMock';
import { i18n } from '@/i18n';
import VerifyEmailUniquenessDto from '../dtos/VerifyEmailUniquenessDto';
import ChangePasswordValidateDto from '../dtos/ChangePasswordValidateDto';


class AccountClient {
	private readonly RESOURCE_ACCOUNTS = 'Accounts';

	public async getMe(): Promise<AccountDto> {
		return (await axios.get(`${this.RESOURCE_ACCOUNTS}/me`)).data;
	}

	

	public patch(id: string, patch: PatchObject[], verificationData: string): Promise<void> {
		return axios.patch(`${this.RESOURCE_ACCOUNTS}/${id}`, patch,
			{ 
				headers: { "x-verification-data": verificationData },
				errorMessages: new Map([
					[403, i18n.global.t("toast.messages.verificationFailed")],
					[409, i18n.global.t("toast.messages.emailAlreadyExists")]
				])
			});
	}

	public async changePasswordVerify(id: string, data: ChangePasswordDto): Promise<void> {
		return axios.put(`${this.RESOURCE_ACCOUNTS}/${id}/verify-change-password`, data);
	}

	public async getSecondFactors(id: string): Promise<SecondFactorDto[]> {
		return (await axios.get(`${this.RESOURCE_ACCOUNTS}/${id}/second-factors`)).data;
	}

	public async verifyMyNewEmailUniqueness(data: VerifyEmailUniquenessDto): Promise<boolean> {
		return await (await axios.post(`${this.RESOURCE_ACCOUNTS}/me/verify-new-email-uniqueness`, data)).data;
	}

	public async validateMyNewPassword(data: ChangePasswordValidateDto): Promise<void> {
		return await axios.post(`${this.RESOURCE_ACCOUNTS}/me/validate-new-password`, data);
	}
}

//export default process.env.NODE_ENV === 'production' ? AccountClient : AccountClientMock
export default AccountClient