
    import { defineComponent, onMounted, ref, computed, watch } from 'vue';
    import AppHeader from '@/components/AppHeader.vue';
    import AppFooter from '@/components/AppFooter.vue';
    import UAppLayout from '@/components/common/UAppLayout.vue';
    import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';
    import { useStore } from '@/store';
    import { WebSocketMessageTypes } from './types/WebSocketMessageTypes';
    import LoginService from './services/LoginService';
    import WebSocketFunctions from './help/WebSocketFunctions';
    import config from './config';
    import { useI18n } from 'vue-i18n';

    export default defineComponent({
        components: { UAppLayout, AppHeader, AppFooter },

        setup() {
            const { t } = useI18n();
            const pretimeoutDialogIsVissible = ref(false);
            const bgName = ref("");
            const header = ref(null);
            const store = useStore();
            const loginService = new LoginService();
            let helloMessageId = '';
            const interval = ref(0);
            onMounted(async () => {
                document.title = 'Unihub' + config.envName;
                if (store.getters.isRecaptchaEnabled) {
                    const reCaptcha = useReCaptcha();
                    if (reCaptcha) {
                        await reCaptcha.recaptchaLoaded();
                        reCaptcha?.instance.value?.hideBadge();
                    }
                }
            });

            async function startPingTimer(): Promise<void> {
                clearInterval(interval.value);
                let timer = store.state.app.wsPingTimeoutInSeconds ?? 30;
                interval.value = setInterval(async function () {
                    if (--timer < 0) {
                        timer = store.state.app.wsPingTimeoutInSeconds ?? 30;
                        if (store.getters.pingActivity) {
                            await WebSocketFunctions.sendMessage(WebSocketMessageTypes.ping);
                            pretimeoutDialogIsVissible.value = false;
                        }
                    }
                }, 1000);
            }

            watch(() => store.state.socket.message, function () {
                try {
                    const message = JSON.parse(store.state.socket.message.toString());

                    if (helloMessageId && helloMessageId == message.replyTo) {
                        if (message.ack == true) {
                            startPingTimer();
                        }
                    }else if ( store.state.messages.findIndex(m=>m.id == message.replyTo) > -1){
                        const storedMessage = store.state.messages.find(m => m.id = message.replyTo);
                        if(storedMessage && storedMessage.messageType == WebSocketMessageTypes.ping){
                            store.state.lastWSPing = new Date();
                        }
                    }
                    else if (message && message.user && message.ssoSessionId && message.method && message.application) {
                        if (store.state.principalUsername == message.user &&
                            store.state.sessionState == message.ssoSessionId && store.state.messages.findIndex(m=>m.id == message.id) == -1) {
                            if (message.method == WebSocketMessageTypes.logout) {
                                WebSocketFunctions.sendAckMessage(message.id);
                                loginService.logout();
                            } else if (message.method == WebSocketMessageTypes.ping) {
                                Object(header.value).resetTimer();
                                startPingTimer();
                                store.state.lastWSPing = new Date();
                                WebSocketFunctions.sendAckMessage(message.id);
                            } else if (message.method == WebSocketMessageTypes.pretimeout) {
                                pretimeoutDialogIsVissible.value = true;
                                WebSocketFunctions.sendAckMessage(message.id);
                            }
                        }
                    }
                } catch (exc) {
                    if (store.state.app.logToConsoleEnabled) {
                        console.log('APP: ONMESSAGE');
                        console.error(exc)
                    }
                }
            });

            watch(async () => store.state.socket.isConnected, async function () {
                try {
                    if (store.state.socket.isConnected) {
                        helloMessageId = await WebSocketFunctions.sendMessage(WebSocketMessageTypes.hello);
                    }
                } catch (exc) {
                    if (store.state.app.logToConsoleEnabled) {
                        console.log('APP: Hello');
                        console.error(exc)
                    }
                }
            });
            
            const stayLoggedIn = async () => {
                pretimeoutDialogIsVissible.value = false;
                await WebSocketFunctions.sendMessage(WebSocketMessageTypes.ping);
            };

            return {
                bgName,
                header,
                pretimeoutDialogIsVissible,
                t,
                stayLoggedIn
            }
        }
    });
