<template>
    {{t("tiles.messages.errorNoUnihubPermission")}}
</template>

<script lang="ts">

    import { defineComponent, inject } from 'vue'
    import { useI18n } from 'vue-i18n';

    export default defineComponent({
        setup() {
            const { t } = useI18n();
            return {
                t
            };
        }
    })

</script>

<style lang="scss" scoped>
    ::v-deep .p-dialog {
        max-width: 75vw;
    }
</style>