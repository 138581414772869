<template>
    <div class="u-app-layout" :class="`bg-${backgroundName}`">
        <slot name="header" />

        <div class="layout">
            <div class="container"><slot /></div>
        </div>

        <slot name="footer" />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
    setup() {
        const route = useRoute();
        const backgroundName = ref(route.meta.background);

        watch(route, (curr, _) => { backgroundName.value = curr.meta.background ?? "none" });

        return { backgroundName };
    }
})
</script>

<style lang="scss" scoped>
@import '@/css-framework/mixins';

@mixin bg-image($name) {
    // TODO css-loader probably needed to handle @/assets path
    background-image: url('../../assets/#{$name}_750.png');

    @include media-breakpoint-up(md) {
        background-image: url("../../assets/#{$name}_1024.png");
    }

    @include media-breakpoint-up(lg) {
        background-image: url("../../assets/#{$name}_1920.png");
    }
}

.bg-user-profile {
    @include bg-image('background_user_profile');
}

.bg-tiles {
    @include bg-image('background_tiles');
}

.bg-change-password {
    @include bg-image('background_password_change');
}

.bg-reset-password {
    @include bg-image('background_forgotten_password');
}

.bg-log-out {
    @include bg-image('background_logout');
}
</style>