<template>
    <div class="p-float-label">
        <InputText type="password" 
                    id="strong-pass-value" 
                    :placeholder="label" 
                    class="p-inputtext-lg" 
                    :modelValue="modelValue"
                    @update:modelValue="onChanged"
                    :class="{'p-invalid': v$.val.$invalid && v$.val.$dirty}"
                    :disabled="disabled" />
        <label for="strong-pass-value">{{ label }}</label>
    </div>
    <u-error-container v-if="v$.val.$invalid && v$.val.$dirty">
        <u-error-item v-if="v$.val.required.$invalid">
            {{ t("strongPassword.messages.enterNewPassword") }}
        </u-error-item>
        <u-error-item v-if="v$.val.strongPasswordValidation.$invalid">
            {{strongPasswordMessage}}
            <ul>
                <li>{{t("strongPassword.messages.strongPassword2")}}</li>
                <li>{{t("strongPassword.messages.strongPassword3")}}</li>
                <li>{{t("strongPassword.messages.strongPassword4")}}</li>
                <li>{{t("strongPassword.messages.strongPassword5")}}</li>
            </ul>
        </u-error-item>
    </u-error-container>
    <u-error-container v-if="v$.val.$dirty">
    <small>{{t("changePassword.validations.passwordRules")}}</small>
     </u-error-container>
</template>

<script lang="ts">
import {computed, defineComponent, reactive } from 'vue';
import UErrorContainer from "@/components/common/UErrorContainer.vue";
import UErrorItem from "@/components/common/UErrorItem.vue";
import { required } from '@vuelidate/validators';
import { strongPassword } from '@/validation/Validators';
import useVuelidate from '@vuelidate/core';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: { UErrorContainer, UErrorItem },
    props: {
        modelValue: String,
        label: String,
        disabled: Boolean,
        minPasswordLength: Number
    },
    emits: ['update:modelValue'],

    setup(props, { emit }) {
        const { t } = useI18n();
        const form = reactive({
            val: props.modelValue,
        })
        
        const strongPasswordMessage = computed(() => {
            return t("strongPassword.messages.strongPassword1", {length: props.minPasswordLength})});
            
        const strongPasswordValidation = () => strongPassword(form.val, props.minPasswordLength); 
        const rules = {
            val: { required, strongPasswordValidation }
        };

        const v$ = useVuelidate(rules, form);

        const onChanged = (event: string) => {
            form.val = event;
            v$.value.$touch();
            emit('update:modelValue', event);
        }

        return {
            form,
            v$,
            onChanged,
            t,
            strongPasswordMessage
        }
    },
})
</script>

<style lang="scss" scoped>
ul ul {
    list-style-type: initial;
}
</style>