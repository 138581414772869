import axios from 'axios';
import ExtraPropertyClientMock from './mocks/ExtraPropertyClientMock';
import ExtraPropertyDto from '../dtos/ExtraPropertyDto';

class ExtraPropertyClient  {
	protected name = 'Properties';

	public async create(data: ExtraPropertyDto): Promise<string> {
		return await axios.post(this.name, data);
	}

	public async getMe(name: string, extraname: string, exactMatch: boolean): Promise<ExtraPropertyDto[]> {
		return (await axios.get(`${this.name}/me?name=${name}&extraname=${extraname}&exactMatch=${exactMatch}`)).data;
	}
}

	//export default process.env.NODE_ENV === 'production' ? ExtraPropertyClient : ExtraPropertyClientMock
	export default ExtraPropertyClient

