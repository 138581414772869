
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
    setup() {
        const route = useRoute();
        const backgroundName = ref(route.meta.background);

        watch(route, (curr, _) => { backgroundName.value = curr.meta.background ?? "none" });

        return { backgroundName };
    }
})
