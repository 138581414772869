import dev from "./dev";
import prod from "./prod"
import test from "./test"
import local from "./local"

const isTest = process.env.NODE_ENV === 'testenv'
const isProduction = process.env.NODE_ENV === 'production'
const isDevelopment = process.env.NODE_ENV === 'development'


export default isProduction ? prod : isTest ? test : isDevelopment ? dev : local;