<template>
    <footer>
        <div class="container">
            © UNIQA {{ new Date().getFullYear() }} | UNIHUB | 
            <a href="#">{{ t("appFooter.personalInfoProtection") }} | {{store.state.app.haNodeIdentifier}}</a> |
            {{ appVersion }}
        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n';
import store from '../store';
import { version } from '../../package.json';

export default defineComponent({
    setup() {
        const { t } = useI18n();
        return { t, store, appVersion:version };
    },
})
</script>

<style scoped lang="scss">
@media (max-width: 700px) {
    .container{
        font-size: 12px;   
        padding: 0 2px;
    }
  }
</style>