export default class AuthToken {
    public accessToken: string;
    public refreshToken: string;
    public validTo: Date;

  constructor(accessToken: string, refreshToken: string, validTo: Date) {
    this.accessToken = accessToken
    this.refreshToken = refreshToken
    this.validTo = validTo
  }
}