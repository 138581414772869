export default class TileCmsDto {
	public applicationName = '';
	public name = '';
	public nameSk = '';
	public nameEn = '';
	public description = '';
	public url = '';
	public color = '';
	public colorHover = '';
	public width = '';
	public height = '';
	public tooltipCZ = '';
	public tooltipSK = '';
	public tooltipEN = '';
	public image = '';
	public imageHover = '';
	public openInSameWindow = false;

	public constructor(init?: Partial<TileCmsDto>) {
        Object.assign(this, init);
    }
}

