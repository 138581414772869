<template>
    <Dialog v-model:visible="isVisible" modal>
        <template #header>
            <h3>{{ t("userProfile.messages.enterVerificationPassword") }}</h3>
        </template>

        <div class="dialog-body">
            <InputText v-model="token" :type="typeInput" autofocus />
            <i v-show="!showPassword" class="pi pi-eye" v-on:click="showPassword =!showPassword"></i><i v-show="showPassword" class="pi pi-eye-slash" v-on:click="showPassword =!showPassword"></i>
        </div>
        
        <template #footer>
            <Button :label="t('userProfile.buttons.verify')" 
                    class="p-button-small" 
                    icon="pi pi-check" 
                    :disabled="!token"
                    @click="submit" />
        </template>
    </Dialog>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n';
export default defineComponent({
    props: {
        visible: Boolean
    },
    emits: [ 'update:visible', 'submited' ],
    computed: {
        typeInput(): string {
            return this.showPassword ? 'text' : 'password';
        }
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const token = ref("");
        const isVisible = computed({
            get() {
                return props.visible;
            },
            set(val: boolean) {
                emit('update:visible', val);
            }
        })

        const submit = () => {
            emit('submited', token.value);
            token.value = "";
        }

        return {
            token,
            isVisible,
            submit,
            t
        }
    },
    data() {
        return {
            showPassword: false
        }
    }
})
</script>

<style lang="scss" scoped>

.dialog-body {
    margin: 0 1.5rem;
    display: flex;
}

.p-inputtext{
margin-right: 10px;
}

.pi {
    align-self: center;
}
::v-deep .p-dialog {
    max-width: 75vw;
}

</style>