
    import FinishResetPasswordDto from '@/dtos/FinishResetPasswordDto';
    import ResetPasswordDto from '@/dtos/ResetPasswordDto';
    import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
    import { useRoute } from 'vue-router'
    import PasswordResetClient from '../services/PasswordResetClient';
    import SecondFactorClient from '../services/SecondFactorClient';
    import { useToast } from "primevue/usetoast";
    import AccountClient from '@/services/AccountClient';
    import UErrorContainer from "@/components/common/UErrorContainer.vue";
    import UErrorItem from "@/components/common/UErrorItem.vue";
    import { required, requiredIf } from '@vuelidate/validators';
    import useVuelidate from "@vuelidate/core";
    import UStrongPassword from '@/components/common/UStrongPassword.vue';
    import { useI18n } from 'vue-i18n';
    import config from '../config';
    import router from '../router';
    import { useHead } from '@vueuse/head';
    import ResetPasswordValidateDto from '@/dtos/ResetPasswordValidateDto';
    import SecondFactorDto from '@/dtos/SecondFactorDto';
    import VerificationPrompt from '@/components/VerificationPrompt.vue';
    import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';
    import { useStore } from '@/store';
    import LoginService from '../services/LoginService';

    export default defineComponent({
        components: { UErrorContainer, UErrorItem, UStrongPassword, VerificationPrompt },
        setup() {
            const route = useRoute();
            const toast = useToast();
            const { t } = useI18n();
            const store = useStore();

            const resetClient = new PasswordResetClient();
            const secondFactorClient = new SecondFactorClient();
            let secFactor: SecondFactorDto;
            let userId = "", resetToken = "";
            let useSecondFactorVerification = false;
            const minPasswordLength = ref(10);
            const secondFactorMissing = ref(false);
            const showVerifyPrompt = ref(false);
            const resetTokenValid = reactive(ref(false)), username = reactive(ref("")),
                loading = ref(false), saving = ref(false);

            const savingIcon = computed(() => saving.value ? "pi pi-spin pi-spinner" : "");
            const verifyPrompt = ref(null);
            let verificationCodeSent = false;
            const reCaptcha = useReCaptcha();

            let recaptchaToken = "";
            const displayModal = ref(false);
            const loginService = new LoginService();
            const baseUrl = `${config.authRedirectUri}`;

            function getFullPath(path: string): string {
                if (baseUrl.endsWith('/') && path.startsWith('/')) {
                    path = path.substring(1);
                }
                return baseUrl + path;
            }
            const redirectToLogIn = () => {
                const tilesPath = router.resolve({ name: 'tiles' }).fullPath;
                loginService.login(getFullPath(tilesPath));
            };
            const resetPasswordUrl = () => {
                const resetPassworPath = router.resolve({ name: 'ResetPassword' }).fullPath;
                return getFullPath(resetPassworPath);
            }
            const getRecaptchaToken = async () => {
                if (!store.getters.isRecaptchaEnabled) {
                    return true;
                }
                recaptchaToken = "";
                // (optional) Wait until recaptcha has been loaded.
                if (reCaptcha) {
                    await reCaptcha.recaptchaLoaded();
                    recaptchaToken = await reCaptcha.executeRecaptcha('resetPassword');
                }

                if (recaptchaToken == null || recaptchaToken == undefined || recaptchaToken == "") {
                    toast.add({
                        severity: 'error',
                        summary: t("toast.titles.error"),
                        detail: t("toast.messages.recaptchaError")
                    });
                    return false;
                }

                return true;
            }

            useHead({
                meta: [
                    {
                        name: `robots`,
                        content: "noindex",
                    },
                ],
            });

            onMounted(async () => {
                userId = route.query["uid"]?.toString() ?? "";
                resetToken = route.query["token"]?.toString() ?? "";

                try {
                    loading.value = true;
                    const res = await resetClient.verify(new ResetPasswordDto({
                        userId: userId,
                        resetToken: resetToken
                    }));

                    if (res.minPasswordLength) {
                        minPasswordLength.value = res.minPasswordLength;
                    }

                    resetTokenValid.value = res.tokenValid;
                    username.value = res.username;
                    useSecondFactorVerification = res.useSecondFactorVerification;
                    if (res.tokenValid && useSecondFactorVerification) {
                        try {
                            secFactor = (await new AccountClient().getSecondFactors(userId))[0];
                            if (secFactor == null) {
                                secondFactorMissing.value = true;
                                toast.add({
                                    severity: 'error',
                                    summary: t("toast.titles.error"),
                                    detail: t("toast.messages.secondFactorMissing")
                                });
                            }
                        } catch {
                            // intercepted by axios
                        }
                    }
                } catch {
                    // intercepted by axios
                }

                loading.value = false;
            });

            const form = reactive({
                newPass: "",
                newPassVerify: "",
            });

            const passwordsMatch = () => form.newPass === form.newPassVerify; // TODO duplicated
            const rules =
            {
                newPassVerify: { required, passwordsMatch },
            };

            const v$ = useVuelidate(rules, form);

            const authenticate = async () => {
                try {
                    if (!verificationCodeSent) {
                        await secondFactorClient.authenticate(secFactor.id, recaptchaToken);
                        Object(verifyPrompt.value).startTimer();
                        verificationCodeSent = true;
                    }
                    showVerifyPrompt.value = true;
                } catch {
                    // intercepted by axios
                }
            };

            const resendVerificationCode = async () => {
                if (!await getRecaptchaToken()) {
                    return;
                }

                verificationCodeSent = false;
                await authenticate();
            };

            const continueSaving = async (verificationData: string) => {
                saving.value = true;
                try {
                     if (useSecondFactorVerification) {
                        if (!await getRecaptchaToken()) {
                            return;
                        }
                     }

                    const res = await resetClient.finish(new FinishResetPasswordDto({
                        userId: userId,
                        resetToken: resetToken,
                        twoFactorToken: verificationData,
                        newPassword: form.newPass,
                        recaptchaToken: recaptchaToken
                    }));

                    if (!res.tokenValid) {

                        toast.add({
                            severity: 'error',
                            summary: t("toast.titles.error"),
                            detail: t("toast.messages.invalidVerificationCode"),
                            life: 3000
                        })
                    } else {
                        if (useSecondFactorVerification) {
                            showVerifyPrompt.value = false;
                            verificationCodeSent = false;
                        }
                        displayModal.value = true;
                    }
                } catch {
                    // intercepted by axios
                }
                saving.value = false;

            };

            const changePassword = async (valid: boolean) => {
                v$.value.$touch();
                if (!valid) {
                    return;
                }

                if (!await getRecaptchaToken()) {
                    return;
                }

                if (useSecondFactorVerification) {
                    try {
                        await resetClient.validate(new ResetPasswordValidateDto({
                            userId: userId,
                            resetToken: resetToken,
                            newPassword: form.newPass
                        }));

                        await authenticate();
                    }
                    catch {
                        // intercepted by axios
                    }
                } else {
                    continueSaving("");
                }
            };

            return {
                form,
                v$,
                userId,
                resetToken,
                username,
                resetTokenValid,
                loading,
                saving,
                savingIcon,
                changePassword,
                t,
                showVerifyPrompt,
                verifyPrompt,
                continueSaving,
                secondFactorMissing,
                resendVerificationCode,
                minPasswordLength,
                store,
                displayModal,
                redirectToLogIn,
                resetPasswordUrl
            };
        },
    })
