import axios from 'axios';
import AuthTokenRequestDto from '../dtos/AuthTokenRequestDto';
import AuthTokenDto from '../dtos/AuthTokenDto';
import TokenClientMock from './mocks/TokenClientMock';

class TokenClient {
	private RESOURCE_TOKEN = 'keycloak';

	public async getToken(data: AuthTokenRequestDto): Promise<AuthTokenDto> {
		const result = await axios.post(`${this.RESOURCE_TOKEN}/token`, data, { supressErrorMessage: true });
		return result.data;
	}

	public async refreshToken(data: AuthTokenRequestDto): Promise<AuthTokenDto> {
		const result = await axios.post(`${this.RESOURCE_TOKEN}/refreshToken`, data, 
			{ supressErrorMessage: true });

		return result.data;
	}
}

//export default process.env.NODE_ENV === 'production' ? TokenClient : TokenClientMock
export default TokenClient