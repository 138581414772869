export default class GeneralFunction  {

    public cutEmail(text: string): string {
        let result = text;
        if (result.indexOf("#") > 0) {
            if (!this.checkEmailOrderingSuffix(text)) {
                text = text.replace("@", "");
            }
            result = text.substring(0, text.indexOf("#"));
            if (this.checkEmailSuffix(text)) {
                return result;
            }
        }
        return text;
    }

    public checkEmailOrderingSuffix(text: string): boolean {
        if (text.indexOf("#") > -1) {
            const suffix = text.substring(text.indexOf("#"), text.length);
            if (suffix.indexOf("@") > -1) {
                return false;
            }
        }
        return true;
    }

    public checkEmailSuffix(text: string): boolean {
        const suffix = text.substring(text.indexOf("#")).replace("#", "");
        if (/^[A-Za-z0-9\-._]+$/.test(suffix)) {
            return true;
        }
        return false;
    }
}


