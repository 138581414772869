import AccountDto from '@/dtos/AccountDto';
import AuthToken from '@/model/AuthToken';
import store from '../store/index'
import WebSocketDto from '@/dtos/WebSocketDto';
import main from "../main";
import { WebSocketMessageTypes } from '@/types/WebSocketMessageTypes';
import config from '../config';
import { AppConfig, getCurrentInstance } from 'vue';
import { ActionTypes } from '@/store/ActionTypes';
import { MutationTypes } from '../store/MutationTypes';
import Message from '@/model/Message';
import WebSocketAckDto from '@/dtos/WebSocketDtoAck';

export default class WebSocketFunctions {

    public static async sendMessage(messageType: WebSocketMessageTypes): Promise<string> {
        let messageId = '';
        if (store.state.user && 
            messageType && 
            store.state.token && 
            store.state.socket.isConnected && 
            store.state.sessionState && 
            store.state.principalUsername) {
                await store.dispatch(ActionTypes.tryRefreshToken, store.state.token);
                const webSocketDto = new WebSocketDto();
                webSocketDto.id = crypto.randomUUID();
                webSocketDto.application = "unihub";
                webSocketDto.method = messageType;
                webSocketDto.ssoSessionId = store.state.sessionState;
                webSocketDto.user = store.state.principalUsername;
                webSocketDto.token = store.state.token.accessToken;
                messageId = webSocketDto.id;
                const json = JSON.stringify(webSocketDto);
                if(store.state.app.logToConsoleEnabled){
                    console.log('SOCKET: SEND');
                    console.log(json);
                }
                main.config.globalProperties.$socket.send(json);
                store.commit(MutationTypes.saveMessage, new Message(messageId, messageType));
                try{
                    if(messageType == WebSocketMessageTypes.bye){
                        main.config.globalProperties.$disconnect();
                    }
                }
                catch{
                    //log out z Unihubu
                }
            
        }
        return messageId;
    }

    public static sendAckMessage(messageId: string){
        const webSocketAckDto = new WebSocketAckDto();
        webSocketAckDto.id = crypto.randomUUID();
        webSocketAckDto.ack = "true";
        webSocketAckDto.replyTo = messageId;
        const json = JSON.stringify(webSocketAckDto);
        main.config.globalProperties.$socket.send(json);
    }
}
