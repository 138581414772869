import AuthTokenRequestDto from '@/dtos/AuthTokenRequestDto';
import AuthToken from '@/model/AuthToken';
import TokenClientMock from './mocks/TokenClientMock';
import TokenClient from './TokenClient';

export default class AuthTokenService {
    private readonly client: TokenClient;

    constructor() {
        this.client = new TokenClient();
    }

    public async getToken(code: string, redirectUri: string): Promise<AuthToken> {
        const res = await this.client.getToken(new AuthTokenRequestDto({ 
            code, 
            redirectUri
        }));

        return new AuthToken(res.access_token, res.refresh_token, this.getValidTo(res.expires_in));
    }

    public async refreshToken(refreshToken: string): Promise<AuthToken> {
        const res = await this.client.refreshToken(new AuthTokenRequestDto({ refreshToken }));
        return new AuthToken(res.access_token, res.refresh_token, this.getValidTo(res.expires_in));
    }

    private getValidTo(duration: number) {
        const validTo = new Date();
        validTo.setMinutes(validTo.getMinutes() + (duration / 60) - 1);

        return validTo;
    }
}