
	import { defineComponent } from 'vue';

	export default defineComponent({
		name: 'BaseLabel',
		props: {
			label: String
		}
	}
	);


