import axios from 'axios';
import Agent360Dto from '../dtos/Agent360Dto';

class C360Client {
	private readonly RESOURCE_C360 = 'C360';
	
	public async getAgent(): Promise<Agent360Dto[]> {
		return (await axios.get(`${this.RESOURCE_C360}`)).data;
	}
}

export default C360Client