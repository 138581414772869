import { createI18n } from 'vue-i18n';

import csCZ from '@/locale/cs-CZ.json';
import skSK from '@/locale/sk-SK.json';
import enUS from '@/locale/en-US.json';

const messages = {
	'cs-CZ': csCZ,
	'sk-SK': skSK,
	'en-US': enUS
}

const currentLocale = Object.keys(messages).find(str => str.includes(navigator.language));
export const i18n = createI18n({
	legacy: false,
	locale: currentLocale,
	fallbackLocale: 'cs-CZ',
	messages
});