export enum MutationTypes {
	setUser = "setUser",
	removeUser = "removeUser",
	setToken = "setToken",
	setShowCheckPhoneDialog = "setShowCheckPhoneDialog",
	showPasswordExpirationDialog = "showPasswordExpirationDialog",
	initApp = "initApp",
	setRecaptcha = "setRecaptcha",
	setImpersonification = "setImpersonification",
	loginOut = "loginOut",
	setSSO = "setSSO",
	SOCKET_ONOPEN = 'SOCKET_ONOPEN',
	SOCKET_ONCLOSE = 'SOCKET_ONCLOSE',
	SOCKET_ONERROR = 'SOCKET_ONERROR',
	SOCKET_ONMESSAGE = 'SOCKET_ONMESSAGE',
	SOCKET_RECONNECT = 'SOCKET_RECONNECT',
	SOCKET_RECONNECT_ERROR = 'SOCKET_RECONNECT_ERROR',
	saveMessage = "saveMessage"
}