export default class FinishResetPasswordDto {
	public userId = '';
	public resetToken = '';
	public twoFactorToken = '';
	public newPassword = '';
	public recaptchaToken = '';

	public constructor(init?: Partial<FinishResetPasswordDto>) {
        Object.assign(this, init);
    }
}