<template>
	<label>{{label}} </label>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';

	export default defineComponent({
		name: 'BaseLabel',
		props: {
			label: String
		}
	}
	);


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	

	

</style>
