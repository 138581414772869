
    import { computed, defineComponent, ref } from 'vue'
    import { useI18n } from 'vue-i18n';

    export default defineComponent({
        props: {
            visible: Boolean
        },
        emits: ['update:visible', 'submited', 'resendVerificationCode'],
        setup(props, { emit }) {
            const { t } = useI18n();
            const token = ref("");
            const countDownText = ref("");
            const interval = ref(0);
            const resendButtonDisabled = ref(true);
            const resendButtonText = t("resetPasswordVerify.buttons.resendVerificationCode");
            const resendButtonTextTimer = t("resetPasswordVerify.buttons.resendVerificationCodeIn");
            const displayCountDownButton = ref(false);
            const isVisible = computed({
                get() {
                    return props.visible;
                },
                set(val: boolean) {
                    emit('update:visible', val);
                }
            })

            const submit = () => {
                emit('submited', token.value);
                token.value = "";
            }

            const resendVerificationCode = () => {
                emit('resendVerificationCode');
            }
            
            const startTimer = () => {
                displayCountDownButton.value = true;
                resendButtonDisabled.value = true;
                countDownText.value =  resendButtonTextTimer + " 02:00";
                clearInterval(interval.value);
                let timer = (60 * 2) - 1, minutes, seconds;

                interval.value = setInterval(function () {
                    minutes = parseInt((timer / 60).toString(), 10);
                    seconds = parseInt((timer % 60).toString(), 10);

                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    seconds = seconds < 10 ? "0" + seconds : seconds;
                    countDownText.value = resendButtonTextTimer + " " + minutes + ":" + seconds;

                    if (--timer < 0) {
                        countDownText.value = resendButtonText;
                        clearInterval(interval.value);
                        resendButtonDisabled.value = false;
                    }
                }, 1000);
            }

            return {
                token,
                isVisible,
                submit,
                countDownText,
                startTimer,
                resendButtonDisabled,
                resendVerificationCode,
                t,
                displayCountDownButton
            }
        },
    })
