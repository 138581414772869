
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/store';

export default defineComponent({
    setup() {
        const store = useStore();
        const isImpersonification = computed(() => store.getters.isImpersonification);
        const time = ref('');
        const interval = setInterval(() => {
            time.value = Intl.DateTimeFormat(navigator.language,
                {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                }).format()
        }, 1000)
        return { isImpersonification, time };
    }
})
