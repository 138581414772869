import axios from 'axios';
import TileCmsDto from '../dtos/TileCmsDto';
import TilesClientMock from './mocks/TilesClientMock';
import TilesSequenceDto from '@/dtos/TilesSequenceDto';

class TilesClient {
	protected name = 'Tiles';
	public async getAll(): Promise<TileCmsDto[]> {
		return (await axios.get(`${this.name}`)).data;
	}

	public async SaveTilesSequence(sequenceDataDto: TilesSequenceDto): Promise<void>{
		return (await axios.post(`${this.name}/saveSequence`, sequenceDataDto)).data;
	}
}

//export default process.env.NODE_ENV === 'production' ? TilesClient : TilesClientMock
export default TilesClient
