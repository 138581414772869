export default class AccountDto {
	public id = '';
	public firstName = '';
	public lastName = '';
	public birthDate = undefined as Date | undefined;
	public phoneNumber= '';
	public brokerId= '';
	public password= '';
	public email = '';
	public state = '';
	public stateName = '';
	public brokerName = '';
	public verificationToken = '';
	public usePasswordConfirmation = false;
	public phoneNumberIsEditable = false;
	public secondFactorActivationDate = undefined as Date | undefined;
	public username = '';
	public technicalAccount = false;
	public type = '';
	public passwordExpirationDate = '';
	public showPasswordExpirationWarning = false;
	public secondaryEmail = undefined as string | undefined;

	public constructor(init?: Partial<AccountDto>) {
        Object.assign(this, init);
    }
}