export default class Agent360Dto {
	public agentNumber = '';
	public countryCode = '';
	public agentType = '';
	public regNum = '';
	public branchName = '';
	public companyRepresentative = '';
	public distributionChannel = '';
	public ns = '';
	public firstName = '';
	public lastName = '';
	public birthNumber = '';
	public companyName = '';
	public companyID = '';
	public email = '';
	public phone = '';

	public constructor(init?: Partial<Agent360Dto>) {
		Object.assign(this, init);
	}
}