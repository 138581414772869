

	import config from '@/config';
	import { useStore } from '@/store';
    import { computed, defineComponent, onMounted } from 'vue';
    import { useI18n } from 'vue-i18n';
	import { useHead } from '@vueuse/head';
	import Agent360Dto from '../dtos/Agent360Dto';
    import C360Client from '../services/C360Client';
    import WebSocketFunctions from '../help/WebSocketFunctions';
    import { WebSocketMessageTypes } from '@/types/WebSocketMessageTypes';
    export default defineComponent({
        setup() {
            const { t } = useI18n();
            const store = useStore();
            useHead({
                meta: [
                    {
                        name: `robots`,
                        content: "noindex",
                    },
                ],
            });

            const buttons = [
                {
                    id: 0,
                    label: t("damageReportSK.buttons.kasko"),
                    url: `${config.damageReportUrlBaseSK}/vozidla/havarijne-poistenie/?stredisko={stred}&email={email}`
                },
                {
                    id: 1,
                    label: t("damageReportSK.buttons.pzpDamaged"),
                    url: `${config.damageReportUrlBaseSK}/vozidla/povinne-zmluvne-poistenie/poskodeny/?stredisko={stred}&email={email}`
                },
                {
                    id: 2,
                    label: t("damageReportSK.buttons.pzpPest"),
                    url: `${config.damageReportUrlBaseSK}/vozidla/povinne-zmluvne-poistenie/poisteny/?stredisko={stred}&email={email}`
                },
                {
                    id: 3,
                    label: t("damageReportSK.buttons.property"),
                    url: `${config.damageReportUrlBaseSK}/majetok-zodpovednost/majetok/?stredisko={stred}&email={email}`
                },
                {
                    id: 4,
                    label: t("damageReportSK.buttons.responsibility"),
                    url: `${config.damageReportUrlBaseSK}/majetok-zodpovednost/zodpovednost/?stredisko={stred}&email={email}`
                },
                {
                    id: 5,
                    label: t("damageReportSK.buttons.traveling"),
                    url: `${config.damageReportUrlBaseSK}/cestovanie/cestovne-poistenie/?stredisko={stred}&email={email}`
                },
                {
                    id: 6,
                    label: t("damageReportSK.buttons.persons"),
                    url: `${config.damageReportUrlBaseSK}/osoby/zivot-zdravie-uraz/?stredisko={stred}&email={email}`
                },
            ]
            
            const user = computed(() => {
                return {
                    email: store.state.user?.email
                }
            });

            return {
                buttons, t, user
            }
        },
        methods: {
            startDamage(url: string, email: string) {
                const urlReplace = url?.replace("{stred}", this.c360Agent.ns).replace("{email}", email);
                window.open(urlReplace, "_blank");
            },
            async load() {
                const defaultNS = "N/A";
                const c360Client = new C360Client();
                try {
                    Object.assign(this.c360Agent, await c360Client.getAgent());
                } catch (error) {
                    // intercepted by axios
                }
                this.c360Agent.ns = this.c360Agent.ns ?? defaultNS;
            }
		},
        data() {
            const c360Agent = new Agent360Dto();
            return {				
                c360Agent
			}
		},
		mounted() {
			this.load();
        }
	});

