<template>
    <header>
        <nav class="u-navbar" :style="isImpersonification ? 'background: red;' : ''">
            <div class="u-app-layout-container">
                <slot name="banner"/>
                <div class="u-nav-menu">
                    <slot name="menu" />
                </div>                
            </div>       
        </nav>
        <div class="header-time"> {{time}}</div>
    </header>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/store';

export default defineComponent({
    setup() {
        const store = useStore();
        const isImpersonification = computed(() => store.getters.isImpersonification);
        const time = ref('');
        const interval = setInterval(() => {
            time.value = Intl.DateTimeFormat(navigator.language,
                {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                }).format()
        }, 1000)
        return { isImpersonification, time };
    }
})
</script>