

import { computed, defineComponent, reactive, ref } from 'vue';	
import PasswordResetClient from '../services/PasswordResetClient';
import { useI18n } from 'vue-i18n';
import router from '../router';
import { useHead } from '@vueuse/head';

export default defineComponent({   
	setup() {
		const { t } = useI18n();
		const client = new PasswordResetClient();

		const loading = ref(false), emailSent = ref(false);
		const loadingIcon = computed(() => loading.value ? "pi pi-spin pi-spinner" : "");
		const form = reactive({
			username: ""
		});
		const passwordExpiredMessage = ref('');

        useHead({
            meta: [
                {
                    name: `robots`,
                    content: "noindex",
                },
            ],
        });

		const startPasswordReset = async () => {
			if (loading.value || !form.username) {
				return;
			}

			loading.value = true;
			try {
				const username = encodeURIComponent(form.username);
				const res = await client.init(username);
				emailSent.value = true;
			} catch {
				// intercepted by axios
			}

			loading.value = false;
		};

		if(router.currentRoute.value.query.expired !== undefined)
		{
			passwordExpiredMessage.value = t("resetPassword.pageTitles.passwordExpired");	
		}

		return {
			form,
			emailSent,
			loading,
			loadingIcon,
			t,
			startPasswordReset,
			passwordExpiredMessage
		}			
	}
});

