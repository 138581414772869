
import { computed, defineComponent, ref } from 'vue'
import UAppHeader from '@/components/common/UAppHeader.vue'
import UIconButton from '@/components/common/UIconButton.vue'
import { useStore } from '@/store';
import LoginService from '@/services/LoginService';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import EventBus from '@/help/EventBus';

export default defineComponent({
    components: {UAppHeader, UIconButton},
    setup() {
        const timer = ref(null);
        const store = useStore();
        const { t } = useI18n();
        const loginService = new LoginService();
        const route = useRoute();
        const showLogoutDialog = ref(false);

        const firstName = computed(() => store.state.user?.firstName);
        const lastName = computed(() => store.state.user?.lastName);
        const isLoggedIn = computed(() => store.getters.isUserLoggedIn);
        const isImpersonification = computed(() => store.getters.isImpersonification);
        const logout = () => {
            loginService.logout();
        };
        const to = computed(() => {
            return route.name == "LogOut" ? '/LogOut' : '/';
        });

        const menu = ref();
        const menuItems = ref([
            {
                items: [
                    {
                        label: 'Přeskládat dlaždice',
                        command: () =>{
                            EventBus.emit('sortModeEnabled');
                        }
                    }
                ]
            }
        ]);

        const toggleMenu = (event: any) => {
            menu.value.toggle(event);
        };

        function resetTimer(){
            Object(timer.value).resetCounter();
        }

        function isMobile(){
            return screen.width <= 450;
        }

        function showLogoutDialogClick(){
            showLogoutDialog.value = true;
        }

        return {
            firstName,
            lastName,
            isLoggedIn,
            logout,
            isImpersonification,
            t,
            to,
            timer,
            resetTimer,
            menuItems,
            menu,
            toggleMenu,
            isMobile,
            showLogoutDialog,
            showLogoutDialogClick
        };
    }
})
