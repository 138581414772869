const HasDigit = (str: string) => /\d/.test(str);
const HasLower = (str: string) => /[a-z]/.test(str);
const HasUpper= (str: string) => /[A-Z]/.test(str);
const AllLetterOrDigit = (str: string) => Array.from(str).every(s => HasUpper(s) || HasLower(s) || HasDigit(s));

const Score = (password: string) => 
    (AllLetterOrDigit(password) ? 0 : 1)
    + (HasDigit(password) ? 1 : 0)
    + (HasLower(password) ? 1 : 0)
    + (HasUpper(password) ? 1 : 0);

export default function(password: string, minPasswordLength: number): boolean {
    return password.length >= minPasswordLength && Score(password) >= 3;   
}