
import { defineComponent } from 'vue'
import TileCmsDto from '@/dtos/TileCmsDto';
import AuditClient from '@/services/AuditClient';
import CreateAuditDto from '@/dtos/CreateAuditDto';
import { useStore } from '@/store';

    export default defineComponent({
    props: {
        tileData: TileCmsDto,
        blockLink: Boolean
    },
 
    setup(props) {
        const auditClient = new AuditClient();
        const store = useStore();
        const tileUri = props.tileData?.url;
        const tileStyle = {
            "--background-color": props.tileData?.color ?? "",
            "--background-color-hover": props.tileData?.colorHover ?? "",
            "--color": props.tileData?.color ? "white" : "",
            "--color-hover": props.tileData?.color || props.tileData?.colorHover ? "" : "#005ca9",
            "--icon-filter": props.tileData?.color ? "invert(100%)" : "",
            "--icon-filter-hover": props.tileData?.colorHover ? "" : "var(--icon-filter)"
        }
    
        const tileImageStyle = {
            "width": props.tileData?.width ?? "",
            "height": props.tileData?.height ?? ""
        };

        const makeSvgObject = (image: string | undefined) => {
            if (image) {
                return URL.createObjectURL(new Blob([image], {type: 'image/svg+xml'}))
            }

            return image;
        }

        function isEmpty(text: string): boolean {
            return (!text || 0 === text.length);
        }

        function getNameByBrowserLanguage(data: TileCmsDto | undefined): string {
            if (data) {
                switch (navigator.language) {
                    case "cs":
                    default:
                        return data.name;
                    case "sk":
                        return !isEmpty(data.nameSk) ? data.nameSk : data.name;
                    case "en":
                        return !isEmpty(data.nameEn) ? data.nameEn : data.name;
                }
            }
            else {
                return "";
            }
        }       

        function pushUri(){
            try{
                const createAuditDto = new CreateAuditDto();
                createAuditDto.auditType = "UnihubAppOpened";
                createAuditDto.callerApplicationId = "UNIHUB";
                createAuditDto.description = "Opened app " + props.tileData?.applicationName+ ".";
                if(store.getters.isImpersonification){
                    createAuditDto.description += " (Impersonated)";
                } 
                
                if(store.state.user){
                    createAuditDto.userAccountId = store.state.user.id;
                }
                auditClient.createAudit(createAuditDto);
            }
            catch{
                //
            }
            if(tileUri){
                window.open(tileUri, props.tileData?.openInSameWindow ? "_self" : "_blank")?.focus();
            }
        }

        function getTooltipByBrowserLanguage(data: TileCmsDto | undefined): string {
            if (data) {
                switch (navigator.language) {
                    case "cs":
                    default:
                        return data.tooltipCZ;
                    case "sk":
                        return data.tooltipSK
                    case "en":
                        return data.tooltipEN;
                }
            }
            else {
                return "";
            }
        }

        return {
            tileStyle,
            tileImageStyle,
            tileName: getNameByBrowserLanguage(props.tileData),
            tileImage: makeSvgObject(props.tileData?.image),
            pushUri,
            getTooltipByBrowserLanguage
        };
    },
})
