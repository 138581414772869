<template>
    <div class="timer" :style="style">{{display}}</div>
</template>

<script lang="ts">
import store from '@/store';
import { defineComponent, ref, computed, onBeforeUnmount } from 'vue'
import LoginService from '@/services/LoginService';
import WebSocketFunctions from '@/help/WebSocketFunctions';
import { WebSocketMessageTypes } from '@/types/WebSocketMessageTypes';

    export default defineComponent({
        props: {
            idleLimit: Number
        },
        setup(props) {
            const display = ref("");
            const defaultCounter = 3600; // seconds
            const counter = ref(defaultCounter);
            let timer: number;
            timer = 0; // TODO ts lint error prefer-const when not assigned here
            const style = computed(() => !store.getters.isImpersonification && counter.value <= 300 ? "filter: invert(100%); color: red; font-weight: bold;" : "");
            const loginService = new LoginService();

            const decreaseTime = async () => {
                counter.value--;
                if (counter.value <= 0) {
                    clearInterval(timer);
                    loginService.logout();
                }

                if(counter.value == store.state.app.wsPretimeoutTimeoutInSeconds){
                    WebSocketFunctions.sendMessage(WebSocketMessageTypes.pretimeout);
                }
                const minute = (counter.value / 60) | 0;
                const second = counter.value % 60 | 0;

                const minutes = `${minute < 10 ? "0" + minute : minute}`;
                const seconds = `${second < 10 ? "0" + second : second}`;

                display.value = `${minutes}:${seconds}`;
            };

            const resetCounter = () => {
                counter.value = store.state.app.idleLimitInSeconds ?? defaultCounter;
            };

            const recordActivity = () => {
                store.state.lastActivity = new Date();
                resetCounter();
            };

            const events = [
                'mousedown', 'mousemove', 'keydown',
                'scroll', 'touchstart'
            ];
            events.forEach(ev => window.addEventListener(ev, recordActivity, true));

            resetCounter();
            timer = setInterval(decreaseTime, 1000);

            onBeforeUnmount(async () => {
                clearInterval(timer);
                for (let i = events.length - 1; i >= 0; i -= 1) {
                    window.removeEventListener(events[i], recordActivity);
                }
            })

            return { counter, display, resetCounter, style }
        },
    })
</script>