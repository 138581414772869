import Vue, { ComponentInternalInstance, getCurrentInstance, createApp, h } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import router from './router'
import store, { key } from './store'
import { ActionTypes } from './store/ActionTypes';
import { MutationTypes } from './store/MutationTypes';
import axios, { AxiosError } from 'axios';
import ToastService from 'primevue/toastservice';
import LoginService from './services/LoginService';
import { i18n } from "@/i18n";
import { createHead } from '@vueuse/head';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import LogOutDialog from './components/LogOutDialog.vue';
import AuthToken from '@/model/AuthToken';
import VueNativeSock from "vue-native-websocket-vue3";
//import 'primeflex/primeflex.css';

import './css-framework/index.scss';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import BaseLabel from '@/components/BaseLabel.vue';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import Password from 'primevue/password';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Checkbox from 'primevue/checkbox';
import ColumnGroup from 'primevue/columngroup';
import Toast from 'primevue/toast';
import Tooltip from 'primevue/tooltip';
import Message from 'primevue/message';
import Skeleton from 'primevue/skeleton';
import DialogService from 'primevue/dialogservice';
import DynamicDialog from 'primevue/dynamicdialog';
import Menu from 'primevue/menu';

import config from './config'; // TODO load config from file using store
import { VueReCaptcha } from 'vue-recaptcha-v3';

// TODO better fix
/* eslint prefer-const: "off" */
let appMounted: Vue.ComponentPublicInstance;
const loginService = new LoginService();
const pathToConfig = window.origin + '/config/config.json';
const SOCKET_ONOPEN = MutationTypes.SOCKET_ONOPEN;
const SOCKET_ONCLOSE = MutationTypes.SOCKET_ONCLOSE;
const SOCKET_ONERROR = MutationTypes.SOCKET_ONERROR;
const SOCKET_ONMESSAGE = MutationTypes.SOCKET_ONMESSAGE;
const SOCKET_RECONNECT = MutationTypes.SOCKET_RECONNECT;
const SOCKET_RECONNECT_ERROR = MutationTypes.SOCKET_RECONNECT_ERROR
const mutations = {
    SOCKET_ONOPEN,
    SOCKET_ONCLOSE,
    SOCKET_ONERROR,
    SOCKET_ONMESSAGE,
    SOCKET_RECONNECT,
    SOCKET_RECONNECT_ERROR
};

const app = createApp(App)
export default app;

axios.get(pathToConfig).then(async response => {
    store.commit(MutationTypes.initApp, response.data);
    if (store.state.app.recaptchaEnabled) await store.dispatch(ActionTypes.getRecaptchaStatus);
	app
	.component('ColumnGroup', ColumnGroup)
    .component('Checkbox', Checkbox)
    .component('Password', Password)
    .component('Dialog', Dialog)
    .component('Dropdown', Dropdown)
    .component('BaseLabel', BaseLabel)
    .component('TabPanel', TabPanel)
    .component('TabView', TabView)
    .component('Button', Button)
    .component('InputText', InputText)
    .component('Column', Column)
    .component('DataTable', DataTable)
    .component('Toast', Toast)
    .component('Message', Message)
    .component('Skeleton', Skeleton)
    .component('DynamicDialog', DynamicDialog)
    .component('Menu', Menu)
    // TODO bug in primevue should be fixed but is not https://github.com/primefaces/primevue/issues/943
    // eslint-disable-next-line
    // @ts-ignore
    .directive('tooltip', Tooltip)
    .use(PrimeVue)
    .use(DialogService)
    .use(store, key)
    .use(i18n)
    .use(router)  
    .use(ToastService)
    .use(createHead());
    
    if(store.state.app.recaptchaSiteKey && store.state.app.recaptchaEnabled){
        app.use(VueReCaptcha, {
            siteKey: store.state.app.recaptchaSiteKey, loaderOptions: {
                //Due to limitations in certain countries it's required to use recaptcha.net instead of google.com.
                useRecaptchaNet: false
            }
        });
    }

    if(config.wsUrl && store.state.app.wsEnabled != false) {
        app.use(VueNativeSock, config.wsUrl, {
            format: "",
            connectManually: true,
            store: store,
            mutations: mutations,
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 3000,
        });
    }
	appMounted = app.mount('#app');
});

function getErrorMessage(method: string | undefined): string {
    switch (method) {
        case "get":
            return i18n.global.t("toast.messages.errorLoadingData");
        case "post":
        case "put":
        case "patch":
            return i18n.global.t("toast.messages.errorSavingData");
        case "delete":
            return i18n.global.t("toast.messages.errorRemovingData");
        default:
            return i18n.global.t("toast.messages.errorCallingApi");
    }
}

axios.defaults.baseURL = config.apiUrl;
axios.interceptors.request.use(async config => {
    if (!config.auth && config.url != 'keycloak/refreshToken' && store.state.token) {
        await store.dispatch(ActionTypes.tryRefreshToken, store.state.token);
        config.headers.Authorization = `Bearer ${store.state.token.accessToken}`;
    }

    return config;
})

axios.interceptors.response.use(response => response,
    (error: AxiosError) => {
        if (error.response?.status == 401 || (error.response?.status == 500 && error.request?.responseURL.indexOf('api/keycloak/token') >= 0)) {
            loginService.logout();
        } else {
            if (!error.config?.supressErrorMessage) {
                if (error.response && error.response.status == 409 && (error.response.data as any).errorCode == 'CommonPasswordsFailed') {
                    appMounted.$toast.add({
                        severity: 'error',
                        summary: i18n.global.t("toast.titles.error"),
                        detail: i18n.global.t("toast.messages.verifyCommonPasswordsFailed")
                    });
                } else if (error.response && error.response.status == 409 && (error.response.data as any).errorCode == 'PasswordHistoryFailed') {
                    appMounted.$toast.add({
                        severity: 'error',
                        summary: i18n.global.t("toast.titles.error"),
                        detail: i18n.global.t("toast.messages.verifyPasswordHistoryFailed")
                    });
                } else if (error.response && error.response.status == 403 && (error.response.data as any).errorCode == 'PasswordVerificationFailed') {
                    appMounted.$toast.add({
                        severity: 'error',
                        summary: i18n.global.t("toast.titles.error"),
                        detail: i18n.global.t("toast.messages.invalidPassword")
                    });
                } else if (error.response && error.response.status == 403 && (error.response.data as any).errorCode == 'InvalidVerificationToken') {
                    appMounted.$toast.add({
                        severity: 'error',
                        summary: i18n.global.t("toast.titles.error"),
                        detail: i18n.global.t("toast.messages.invalidVerificationCode")
                    });
                } else if (error.response && error.response.status == 409 && (error.response.data as any).errorCode == 'VerifyEmailUniquenessFailed') {
                    appMounted.$toast.add({
                        severity: 'error',
                        summary: i18n.global.t("toast.titles.error"),
                        detail: i18n.global.t("toast.messages.emailAlreadyExists")
                    });
                    /*  } else if (error.response && error.response.status == 400 && error.response.data.errorCode == 'UserAccountIdNotSetInToken') {
                          setTimeout(() => {
                              alert(i18n.global.t("tiles.messages.errorNoUnihubPermission"));
                              loginService.logout();
                          }, 500);*/
                } else {
                    const msg = error.config?.errorMessages?.get(error.response?.status ?? 0)
                        || error.config?.errorMessage || getErrorMessage(error.config?.method);

                    appMounted.$toast.add({
                        severity: 'error',
                        summary: i18n.global.t("toast.titles.error"),
                        detail: msg
                    });
                }
            }

            return Promise.reject(error);
        }
    });

axios.interceptors.response.use(res => {
    const isoDateFormat =
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

    const handleDates = (body: any) => { // TODO argument type?
        if (!body || typeof body !== "object") {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (value && typeof value === "string" && isoDateFormat.test(value)) {
                body[key] = new Date(value);
            } else if (typeof value === "object") {
                handleDates(value);
            }
        }
    };

    handleDates(res.data);

    return res;
});

router.beforeEach(async (to, _, next) => {
	switch(to.name) {
		case "ResetPassword":
		case "reset-password-verify":
		case "LogOut":
			next();
			break;

        default:
            if (store.getters.isUserLoggedIn)  {
                next();
            } else if (store.state.token) {

                await store.dispatch(ActionTypes.getUser);

                next();
            } else {
                let authRedirectUri = config.authRedirectUri;
                let path = to.path;
                if (authRedirectUri.endsWith('/') && path.startsWith('/')) {
                    path = path.substring(1);
                }
                
                while(path.startsWith('//')){
                    path = path.slice(1);
                }
                
                const returnUri = `${authRedirectUri}${path}`
                const code = to.query["code"]?.toString();
                if (code) {
                    await store.dispatch(ActionTypes.getToken, { code, returnUri });
                    if (store.state.token) {
                        if (store.state.userSource == undefined) {
                            const dialogRef = appMounted.$dialog.open(LogOutDialog, {
                                props: {
                                    header: i18n.global.t("toast.titles.error"),
                                    modal: true,
                                    dismissableMask: false,
                                    closable: false
                                },
                                templates: {
                                    footer: () => {
                                        return [
                                            h(Button, {
                                                label: i18n.global.t("tiles.buttons.logout"),
                                                onClick: () => {
                                                    loginService.logout();
                                                    dialogRef.close();
                                                }, autofocus: true
                                            })
                                        ]
                                    }
                                }
                            });
                            return;
                        } else if (store.state.authSrc == 'impersonification') {
                            store.commit(MutationTypes.setImpersonification, true);
                        }

                        if (store.state.fromOrig) {
                            store.commit(MutationTypes.setSSO, true);
                        }
                    }
                    await store.dispatch(ActionTypes.getUser);
					if(config.wsUrl && store.state.app.wsEnabled != false && store.state.token && (store.state.token as any) instanceof AuthToken && !store.state.socket.isConnected){
						await appMounted.$.appContext.config.globalProperties.$connect(config.wsUrl + "?token=" + (store.state.token as any).accessToken);
					}
                    next(to.path);
                } else {
                    loginService.login(returnUri);
                }
            }
    }
});

