
    import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
    import BrokerClient from '../services/BrokerClient';
    import AccountClient from '../services/AccountClient';
    import AccountDto from '../dtos/AccountDto';
    import UFloatingLabel from '@/components/common/UFloatingLabel.vue'
    import BrokerDto from '@/dtos/BrokerDto';
    import diff, { PatchObject } from 'json-patch-gen';
    import { useToast } from 'primevue/usetoast';
    import useVuelidate from '@vuelidate/core';
    import { required, email, requiredIf } from '@vuelidate/validators';
    import UErrorContainer from "@/components/common/UErrorContainer.vue";
    import UErrorItem from "@/components/common/UErrorItem.vue";
    import VerificationPrompt from '@/components/VerificationPrompt.vue';
    import PasswordVerificationPrompt from '@/components/PasswordVerificationPrompt.vue';
    import TilesClient from '../services/TilesClient';
    import SecondFactorClient from '@/services/SecondFactorClient';
    import { useStore } from '@/store';
    import { useI18n } from 'vue-i18n';
    import * as EmailValidator from 'email-validator';
    import { useHead } from '@vueuse/head';
    import VerifyEmailUniquenessDto from '@/dtos/VerifyEmailUniquenessDto';
    import SecondFactorDto from '@/dtos/SecondFactorDto';
    import parseMobile from 'libphonenumber-js/mobile';
    import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';
    import General from '../help/GeneralFunction';
    import WebSocketFunctions from '../help/WebSocketFunctions';
    import { WebSocketMessageTypes } from '@/types/WebSocketMessageTypes';
import TileCmsDto from '@/dtos/TileCmsDto';
import { MutationTypes } from '@/store/MutationTypes';

    export default defineComponent({
        components: { UFloatingLabel, UErrorContainer, UErrorItem, VerificationPrompt, PasswordVerificationPrompt },
        setup() {
            const toast = useToast();
            const store = useStore();
            const { t } = useI18n();
            const general = new General();
            const userId = ref(store.state.user?.id ?? "");

            const loading = ref(false), saving = ref(false);
            const savingIcon = computed(() => saving.value ? "pi pi-spin pi-spinner" : "");
            const displayModal = ref(false);
            const displaySecondaryModal = ref(false);
            const accountClient = new AccountClient();
            const broker = reactive(new BrokerDto());
            const modalMessage = ref("");
            const secondaryModalMessage = ref(t('tiles.messages.secondaryEmailEditation'));
            const isSSO = store.state.user?.type == "SSO";
            //SAVEBUTTON - disable save button v pripade ze nebola vykonana ziadna zmena v profile
            //const origAccount = reactive(new AccountDto());
            let origAccount = new AccountDto();
            const account = reactive(new AccountDto());
            const tiles = reactive(Array<TileCmsDto>());

            function mobileValidate(phone: string): boolean {
                const mobileNumber = parseMobile(phone);

                if (mobileNumber) {
                    return mobileNumber.getType() === 'MOBILE';
                }
                return false;
            }

            const secondaryEmailValid = (): boolean =>{
                return account.secondaryEmail == '' || account.secondaryEmail == null || account.secondaryEmail == undefined ? true : EmailValidator.validate(account.secondaryEmail);
            }

            const phoneNumberValid = () => account.phoneNumberIsEditable ?
                (new RegExp('^(\\+|(00))((420)|(421)|(36)|(48))[0-9]{9}$|^(\\+|(00))(((43)|(49))[0-9]{9,11})$')).test(account.phoneNumber) && mobileValidate(account.phoneNumber) :
                true;
            const rules = {
                email: { required },
                phoneNumber: { phoneNumberValid },
                secondaryEmail: { secondaryEmailValid }
            };
            let secFactor: SecondFactorDto;
            const v$ = useVuelidate(rules, account);
            const login = ref('');
            let verificationCodeSent = false;
            const verifyPrompt = ref(null);

            const reCaptcha = useReCaptcha();
            let recaptchaToken = "";

            const getRecaptchaToken = async () => {
                if (!store.getters.isRecaptchaEnabled) {
                    return true;
                }
                recaptchaToken = "";
                // (optional) Wait until recaptcha has been loaded.
                if (reCaptcha) {
                    await reCaptcha.recaptchaLoaded();
                    recaptchaToken = await reCaptcha.executeRecaptcha('resetPassword');
                }

                if (recaptchaToken == null || recaptchaToken == undefined || recaptchaToken == "") {
                    toast.add({
                        severity: 'error',
                        summary: t("toast.titles.error"),
                        detail: t("toast.messages.recaptchaError")
                    });
                    return false;
                }

                return true;
            }

            const emailInvalid = (): boolean => {
                return account.email == '' ? false : !EmailValidator.validate(account.email);
            }

            const emailValidStyle = (): string => {
                return EmailValidator.validate(account.email) ? '' : 'p-invalid';
            }

            const emailChanged = (): boolean => {
                return (account.username == null || account.username == undefined || account.username.length < 1) &&
                    !emailInvalid() && account.email.toLowerCase() != general.cutEmail(origAccount.email).toLowerCase();
            }

            async function loadMe(): Promise<void> {
                Object.assign(account, await accountClient.getMe());
                login.value = account.username ? account.username : account.email;
                origAccount = new AccountDto(account);
                account.email = general.cutEmail(account.email);
                store.commit(MutationTypes.setUser, account);
            }

            const isSecondaryEmailEditationOk = (): boolean =>{
                if (tiles.some(x => x.applicationName == 'HCL'))
                {
                    if (account.email == account.secondaryEmail || account.secondaryEmail?.endsWith('uniqa.cz') || account.secondaryEmail?.endsWith('uniqa.sk')){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
                else
                {
                    return true; // vracime Ok jestli uzivatel nema HCL pravo, tzn. nekontrolujeme editace sekundarniho emailu
                }
            }

            useHead({
                meta: [
                    {
                        name: `robots`,
                        content: "noindex",
                    },
                ],
            });

            onMounted(async () => {
                loading.value = true;
                try {
                    await loadMe();
                    Object.assign(tiles, await new TilesClient().getAll());
                    Object.assign(broker, await new BrokerClient().getById(account.brokerId));
                    //SAVEBUTTON - disable save button v pripade ze nebola vykonana ziadna zmena v profile
                    //Object.assign(origAccount , new AccountDto(account));
                } catch {
                    // intercepted by axios
                }

                loading.value = false;
            });

            const fullName = computed(() => `${account.firstName} ${account.lastName}`);
            const birthDateFormated = computed(() => !account.birthDate
                ? undefined
                : new Intl.DateTimeFormat('cs-CZ').format(account.birthDate));

            const showVerifyPrompt = ref(false);
            const showPasswordVerifyPrompt = ref(false);
            let patch = [] as PatchObject[];

            const authenticate = async () => {
                try {
                    if (!verificationCodeSent) {
                        await new SecondFactorClient().authenticate(secFactor.id, recaptchaToken);
                        Object(verifyPrompt.value).startTimer();
                        verificationCodeSent = true;
                    }
                    showVerifyPrompt.value = true;
                } catch {
                    // intercepted by axios
                }
            };

            const resendVerificationCode = async () => {
                if (!await getRecaptchaToken()) {
                    return;
                }
                verificationCodeSent = false;
                await authenticate();
            };

            const saveChanges = async (valid: boolean) => {
                if (!isSecondaryEmailEditationOk()){
                    displaySecondaryModal.value = true;
                    return;
                }
                const updatedAccount = new AccountDto(account);
                if (general.cutEmail(origAccount.email) == updatedAccount.email) {
                    updatedAccount.email = origAccount.email;
                }
                if (updatedAccount.secondaryEmail === ""){
                    updatedAccount.secondaryEmail = undefined;
                }
                patch = diff(origAccount, updatedAccount);

                if (valid && patch.length > 0) {

                    saving.value = true;
                    try {
                        if (!account.technicalAccount && origAccount.email != updatedAccount.email) {
                            const verifyEmailUniquenessDto = new VerifyEmailUniquenessDto();
                            verifyEmailUniquenessDto.email = updatedAccount.email;
                            await accountClient.verifyMyNewEmailUniqueness(verifyEmailUniquenessDto);
                        }
                        if (account.usePasswordConfirmation) {
                            showPasswordVerifyPrompt.value = true;
                        } else {
                            secFactor = (await accountClient.getSecondFactors(userId.value))[0];
                            if (secFactor) {
                                if (!await getRecaptchaToken()) {
                                    return;
                                }
                                await authenticate();
                            } else {
                                modalMessage.value = t("userProfile.messages.missing2faError", { loginname: login.value})
                                displayModal.value = true;
                            }
                        }
                        // TODO getting second factor is duplicated with changepassword component -> move to service

                    } catch {
                        // intercepted by axios
                    }
                    saving.value = false;
                }
            }

            const continueSaving = async (verificationData: string) => {
                saving.value = true;
                try {
                    const oldEmail = origAccount.email;
                    await accountClient.patch(userId.value, patch, verificationData);

                    if (account.usePasswordConfirmation) {
                        showPasswordVerifyPrompt.value = false;
                    } else {
                        showVerifyPrompt.value = false;
                        verificationCodeSent = false;
                    }

                    try {
                        await loadMe();
                        if (!account.username && oldEmail != origAccount.email) {
                            modalMessage.value = t("userProfile.messages.newLoginName", { loginname: login.value});
                            displayModal.value = true;
                        }
                    } catch {
                        // intercepted by axios
                    }
                    //SAVEBUTTON - disable save button v pripade ze nebola vykonana ziadna zmena v profile
                    //Object.assign(origAccount , new AccountDto(account));

                    v$.value.$reset();

                    toast.add({
                        severity: 'success',
                        summary: 'Uloženo',
                        detail: 'Změny byly uloženy',
                        life: 3000
                    });
                } catch {
                    // intercepted by axios
                }

                saving.value = false;
            }

            return {
                showVerifyPrompt,
                loading,
                saving,
                savingIcon,
                v$,
                account,
                broker,
                fullName,
                birthDateFormated,
                saveChanges,
                continueSaving,
                t,
                showPasswordVerifyPrompt,
                login,
                emailInvalid,
                emailValidStyle,
                emailChanged,
                displayModal,
                resendVerificationCode,
                verifyPrompt,
                modalMessage,
                isSSO,
                tiles,
                displaySecondaryModal,
                secondaryModalMessage,
                isSecondaryEmailEditationOk
            }
        },

        computed:{
            hasHCLRight(): boolean{
                return this.tiles.some(x => x.applicationName == 'HCL');
            },

            IsUserFromInternalDistribution(): boolean{
                return this.broker.brokerNumber == 6000 || this.broker.brokerNumber == 3000
                        || this.broker.brokerNumber == 292 || this.broker.brokerNumber == 99999; 
            }
        }

        /*computed: {
            /*disableSaveButton(): boolean
            {
                //SAVEBUTTON - disable save button v pripade ze nebola vykonana ziadna zmena v profile
                let patch = [] as PatchObject[];
                patch = diff(this.origAccount, this.account);
                return patch.length == 0 || this.v$.$invalid || this.emailValidStyle != '';
            }*/
        //},
    });
