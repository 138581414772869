export default class BrokerDto {
	public id = '';
	public name = '';
	public crn = 0;
	public description = '';
	public brokerNumber = 0;

	public constructor(init?: Partial<BrokerDto>) {
        Object.assign(this, init);
    }
}