import AccountDto from '@/dtos/AccountDto';
import AuthToken from '@/model/AuthToken';
import AccountClient from '@/services/AccountClient';
import AuthTokenService from '@/services/AuthTokenService';
import { InjectionKey } from 'vue';
import {
    ActionContext, ActionTree, CommitOptions, createStore, DispatchOptions, GetterTree, MutationTree,
    Store as VuexStore, useStore as baseUseStore
} from 'vuex';
import { ActionTypes } from './ActionTypes';
import { MutationTypes } from './MutationTypes';
import LoginService from '@/services/LoginService';
import InitAppDto from '@/dtos/InitAppDto';
import RecaptchaClient from '@/services/RecaptchaClient';
import main from "../main";
import WebSocketFunctions from '@/help/WebSocketFunctions';
import { WebSocketMessageTypes } from '@/types/WebSocketMessageTypes';
import Message from '@/model/Message';

const tokenService = new AuthTokenService();
const accountClient = new AccountClient();
const loginService = new LoginService();
const recaptchaClient = new RecaptchaClient();

export interface State {
	user?: AccountDto;
	token?: AuthToken;
	showCheckPhoneDialog: boolean;
	showPasswordExpirationDialog: boolean;
	app: InitAppDto;
	recaptchaEnabled: boolean;
	impersonification: boolean;
	sso: boolean;
    socket: {
        // Connection Status
        isConnected: boolean;
        // Message content
        message: string;
        // Reconnect error
        reconnectError: boolean;
    };
    sessionState: string | undefined;
    principalUsername: string | undefined;
    userSource: string | undefined;
    authSrc: string | undefined;
    lastActivity: Date | undefined;
    lastWSPing: Date | undefined;
    messages: Message[];
    fromOrig: string | undefined;
}

export type Mutations<S = State> = {
	[MutationTypes.setUser](state: S, user: AccountDto): void;
	[MutationTypes.removeUser](state: S): void;
	[MutationTypes.setToken](state: S, token: AuthToken): void;
	[MutationTypes.setShowCheckPhoneDialog](state: S, showCheckPhoneDialog: boolean): void;
	[MutationTypes.showPasswordExpirationDialog](state: S, showPasswordExpirationDialog: boolean): void;
	[MutationTypes.initApp](state: S, initApp: InitAppDto): void;
	[MutationTypes.setRecaptcha](state: S, recaptchaEnabled: boolean): void;
	[MutationTypes.setImpersonification](state: S, impersonification: boolean): void;
	[MutationTypes.loginOut](state: S): void;
    [MutationTypes.setSSO](state: S, sso: boolean): void;
    [MutationTypes.saveMessage](state: S, message: Message): void;
}

export type Getters = {
    isUserLoggedIn(state: State): boolean;
    isRecaptchaEnabled(state: State): boolean;
    isImpersonification(state: State): boolean;
    pingActivity(state: State): boolean;
}

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, 'commit'>

type GetTokenAction = {
    code: string;
    returnUri: string;
}

export interface Actions {
    [ActionTypes.getToken](
        { commit }: AugmentedActionContext,
        payload: GetTokenAction
    ): Promise<void>;

    [ActionTypes.getUser](
        { commit }: AugmentedActionContext,
    ): Promise<void>;

    [ActionTypes.tryRefreshToken](
        { commit }: AugmentedActionContext,
        token: AuthToken
    ): Promise<void>;

    [ActionTypes.getRecaptchaStatus](
        { commit }: AugmentedActionContext,
        enabled: boolean
    ): Promise<void>;
}

export type Store = Omit<
    VuexStore<State>,
    'getters' | 'commit' | 'dispatch'>
    & {
        commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
            key: K,
            payload?: P,
            options?: CommitOptions
        ): ReturnType<Mutations[K]>;
    } & {
        getters: {
            [K in keyof Getters]: ReturnType<Getters[K]>
        };
    } & {
        dispatch<K extends keyof Actions>(
            key: K,
            payload?: Parameters<Actions[K]>[1],
            options?: DispatchOptions
        ): ReturnType<Actions[K]>;
    }

export const key: InjectionKey<Store> = Symbol();

export function useStore() {
    return baseUseStore(key) as Store;
}

export default createStore<State>({
	state: {
		user: undefined,
		token: undefined,
		showCheckPhoneDialog: true,
		showPasswordExpirationDialog: true,
		app: new InitAppDto(),
		recaptchaEnabled: false,
		impersonification: false,
        sso: false,
        socket: {
            // Connection Status
            isConnected: false,
            // Message content
            message: "",
            // Reconnect error
            reconnectError: false
        },
        sessionState: undefined,
        principalUsername: undefined,
        userSource: undefined,
        authSrc: undefined,
        lastActivity: undefined,
        lastWSPing: undefined,
        messages: new Array<Message>(),
        fromOrig: undefined
	},

	getters: {
		isUserLoggedIn: (state) => {
			return state.user != undefined;
		},
		isRecaptchaEnabled: (state) => {
			return state.app.recaptchaEnabled;
		},
		isImpersonification: (state) => {
			return state.impersonification;
        },
        pingActivity: (state) => {
            return state.lastActivity && (state.lastWSPing == undefined || (state.lastWSPing && state.lastActivity > state.lastWSPing));
        }
	} as GetterTree<State, State> & Getters,

	mutations: {
		[MutationTypes.setUser](state, user) {
			state.user = user;
		},

		[MutationTypes.removeUser](state) {
			state.user = undefined;
		},

		[MutationTypes.setToken](state, token) {
            state.token = token;
            const tokenAccess = token.accessToken;
            const base64Url = tokenAccess.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            const obj = JSON.parse(jsonPayload);
            state.sessionState = obj.session_state;
            state.principalUsername = obj.principal_username;
            state.userSource = obj.user_source;
            state.authSrc = obj.auth_src;
            state.fromOrig = obj.fromOrig;
		},

		[MutationTypes.setShowCheckPhoneDialog](state, showCheckPhoneDialog) {
			state.showCheckPhoneDialog = showCheckPhoneDialog;
		},
		[MutationTypes.showPasswordExpirationDialog](state, showPasswordExpirationDialog) {
			state.showPasswordExpirationDialog = showPasswordExpirationDialog;
		},
		[MutationTypes.initApp](state, initApp) {
			state.app = initApp;
		},	
		[MutationTypes.setRecaptcha](state, recaptchaEnabled) {
			state.recaptchaEnabled = recaptchaEnabled;
		},
		[MutationTypes.setImpersonification](state, impersonification) {
			state.impersonification = impersonification;
		},
		[MutationTypes.loginOut](state) {
			state.user = undefined;
			state.token = undefined;
			state.showCheckPhoneDialog = true;
			state.showPasswordExpirationDialog = true;
			state.app = new InitAppDto();
			state.recaptchaEnabled = true;
			state.impersonification = false;
            state.sso = false;
            state.sessionState = undefined;
            state.principalUsername = undefined;
            state.userSource = undefined;
            state.authSrc = undefined;
            state.fromOrig = undefined;
            state.socket = {
                isConnected: false,
                message: "",
                reconnectError: false
            }
		},
		[MutationTypes.setSSO](state, sso) {
			state.sso = sso;
        },
        [MutationTypes.SOCKET_ONOPEN](state, event) {
            if (state.app.logToConsoleEnabled) {
                console.log('SOCKET: ONOPEN');
                console.log(event);
            }
            main.config.globalProperties.$socket = event.currentTarget;
            state.socket.isConnected = true;
        },
        [MutationTypes.SOCKET_ONCLOSE](state, event) {
            state.socket.isConnected = false;
        },
        [MutationTypes.SOCKET_ONERROR](state, event) {
            if (state.app.logToConsoleEnabled) {
                console.log('SOCKET: ONERROR');
                console.error(state, event)
            }
        },
        // default handler called for all methods
        [MutationTypes.SOCKET_ONMESSAGE](state, message) {
            if (state.app.logToConsoleEnabled) {
                console.log('SOCKET: ONMESSAGE');
                console.log(message);
            }
            state.socket.message = message.data
        },
        // mutations for reconnect methods
        [MutationTypes.SOCKET_RECONNECT](state, count) {
            console.info(state, count);
        },
        [MutationTypes.SOCKET_RECONNECT_ERROR](state) {
            state.socket.reconnectError = true;
        },
        [MutationTypes.saveMessage](state, message) {
            if (state.messages.length >= 10) {
                state.messages.shift();
            }
            state.messages.push(message);
        }
	} as MutationTree<State> & Mutations,

    actions: {
        async [ActionTypes.getToken]({ commit }, { code, returnUri }) {
            const token = await tokenService.getToken(code, returnUri);
            commit(MutationTypes.setToken, token);
        },
        async [ActionTypes.getUser]({ commit }) {
            const user = await accountClient.getMe();
            commit(MutationTypes.setUser, user);
        },
        async [ActionTypes.tryRefreshToken]({ commit }, token) {
            // note: currently not in use, leaving here in case token refresh becomes 
            // desirable again - please call inside axios request interceptor
            if (token.validTo < new Date()) {
                await tokenService.refreshToken(token.refreshToken).then(refreshed => {
                    commit(MutationTypes.setToken, refreshed);
                }).catch(() => {
                    loginService.logout();
                });
            }
        },
        async [ActionTypes.getRecaptchaStatus]({ commit }) {
            const recaptchaEnabled = await recaptchaClient.getRecaptchaStatusForCurrentNetwork();
            commit(MutationTypes.setRecaptcha, recaptchaEnabled);
        }
    } as ActionTree<State, State> & Actions
}) as Store
