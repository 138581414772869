export default class InitAppDto {
	public recaptchaSiteKey = '';
	public recaptchaEnabled = false;
	public haNodeIdentifier = '';
	public logToConsoleEnabled = false;
	public idleLimitInSeconds = 3600;
	public wsPingTimeoutInSeconds = 30;
	public wsPretimeoutTimeoutInSeconds = 300;
	public wsEnabled = true;
	public secondaryEmailDialogDate = '2024-04-30';
	public version = "v1.0000"
}