import axios from 'axios';

class RecaptchaClient {
	protected name = 'Recaptcha';
	public async getRecaptchaStatusForCurrentNetwork(): Promise<boolean> {
		return (await axios.get(`${this.name}`)).data;
	}
}

export default RecaptchaClient
