import config from "@/config";
import store from "@/store";
import { MutationTypes } from "@/store/MutationTypes";
import AuthTokenService from "./AuthTokenService";
import router from '../router';
import WebSocketFunctions from '../help/WebSocketFunctions';
import { WebSocketMessageTypes } from '@/types/WebSocketMessageTypes';

export default class LoginService {
    private readonly redirectUri = encodeURIComponent(config.authRedirectUri);

    private tokenService: AuthTokenService;

    constructor() {
        this.tokenService = new AuthTokenService();
    }
    
    public login(returnUri: string): void {
        location.href  = this.getAuthUrl("auth", "hyperion", returnUri);
    }

    public async logout(): Promise<void> {
        const returnUri = this.getReturnUrl();
        await WebSocketFunctions.sendMessage(WebSocketMessageTypes.logout);
        await WebSocketFunctions.sendMessage(WebSocketMessageTypes.bye);
        store.commit(MutationTypes.loginOut);
        location.href  = this.getAuthUrl("logout", "hyperion", returnUri);
    }

    private getAuthUrl(authType: string, clientId?: string, returnUri?: string): string {
        const redirectUri = returnUri ?? this.redirectUri;
        const params = [
            `redirect_uri=${redirectUri}`,
            "response_type=code",
            "scope=openid"
        ];

        if (clientId) {
            params.push(`client_id=${clientId}`);
        }

        const url = config.authUrl.endsWith("/") ? config.authUrl.slice(0, -1) : config.authUrl;
        return `${url}/${authType}?${params.join("&")}`;
    }

    private getReturnUrl(): string | undefined {
        const isSSO = store.state.user?.type == 'SSO';
        if(isSSO){
            const baseUrl = `${config.authRedirectUri}`;
            let logOutPath = router.resolve({ name: 'LogOut' }).fullPath;

            if (baseUrl.endsWith('/') && logOutPath.startsWith('/')) {
                logOutPath = logOutPath.substring(1);
            }
            return baseUrl + logOutPath
        }else{
            return undefined
        }
    }
}