<template>
    <u-app-header>
        <template v-slot:banner>
                <router-link :to="to" >
                    <suspense>
                        <u-icon-button :image="isMobile() ? 'logo_unihub_small' : 'logo_unihub'" />
                    </suspense>
                </router-link>
        </template>
    
        <template v-slot:menu v-if="isLoggedIn">
            <span class="user-name">
                {{ firstName }}<br>{{ lastName }}
                <div v-show="isImpersonification">{{ t("appHeader.impersonated")}}</div> 
            </span>
            <router-link to="user-profile">
                <u-icon-button image="user-profile" />
            </router-link>
            <u-icon-button :showTimer="true" image="logout" @click="showLogoutDialogClick" ref="timer" :tooltip="t('appHeader.logoutTooltip')"/>
            <div class="menuContainer">
                <u-icon-button image="menu-burger" @click="toggleMenu" aria-haspopup="true" aria-controls="overlay_menu" />
                <Menu ref="menu" id="overlay_menu" :model="menuItems" :popup="true" />
            </div>
        </template>
    </u-app-header>

    <div class="popup-container" v-if="showLogoutDialog">
        <div class="popup-inner">
            <span class="popup-header">{{t('tiles.messages.warning')}}</span>
            <span class="popup-text">{{t("logOut.titles.logoutDialog")}}</span>
            <div class="popup-buttons">
                <Button class="popup-button" :label="t('logOut.titles.logoutFromAllPOS')" v-on:click="logout"/>
                <Button class="popup-button" :label="t('tiles.buttons.cancel')" v-on:click="showLogoutDialog = false"/>
            </div>
        </div>
    </div>

</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import UAppHeader from '@/components/common/UAppHeader.vue'
import UIconButton from '@/components/common/UIconButton.vue'
import { useStore } from '@/store';
import LoginService from '@/services/LoginService';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import EventBus from '@/help/EventBus';

export default defineComponent({
    components: {UAppHeader, UIconButton},
    setup() {
        const timer = ref(null);
        const store = useStore();
        const { t } = useI18n();
        const loginService = new LoginService();
        const route = useRoute();
        const showLogoutDialog = ref(false);

        const firstName = computed(() => store.state.user?.firstName);
        const lastName = computed(() => store.state.user?.lastName);
        const isLoggedIn = computed(() => store.getters.isUserLoggedIn);
        const isImpersonification = computed(() => store.getters.isImpersonification);
        const logout = () => {
            loginService.logout();
        };
        const to = computed(() => {
            return route.name == "LogOut" ? '/LogOut' : '/';
        });

        const menu = ref();
        const menuItems = ref([
            {
                items: [
                    {
                        label: 'Přeskládat dlaždice',
                        command: () =>{
                            EventBus.emit('sortModeEnabled');
                        }
                    }
                ]
            }
        ]);

        const toggleMenu = (event: any) => {
            menu.value.toggle(event);
        };

        function resetTimer(){
            Object(timer.value).resetCounter();
        }

        function isMobile(){
            return screen.width <= 450;
        }

        function showLogoutDialogClick(){
            showLogoutDialog.value = true;
        }

        return {
            firstName,
            lastName,
            isLoggedIn,
            logout,
            isImpersonification,
            t,
            to,
            timer,
            resetTimer,
            menuItems,
            menu,
            toggleMenu,
            isMobile,
            showLogoutDialog,
            showLogoutDialogClick
        };
    }
})
</script>

<style lang="scss" scoped>
@import '@/css-framework/_colors';
@import '@/css-framework/_mixins';

.menuContainer{
    margin-right: 10px;
}

.logout-dialog-header{
    background-color: $color-primary;
}

.user-name {
      font-size: 14pt;
      line-height: 14pt;
      font-weight: bold;
      color: $color-white;
      text-align: right;
      display: none; // to hide username on narrow screen

      @include media-breakpoint-up(md) {
        display: inline-block;
      }

      @include media-breakpoint-up(lg) {
        font-size: 11pt;
        line-height: 11pt;
      }
    }

    .popup-container{
        position: fixed;
        background-color: rgba(0, 0, 0, 0.2);
        border: 1px solid black;
        
        z-index: 99;

        top: 25%;
        left: 50%;
        /* bring your own prefixes */
        transform: translate(-50%, -50%);

        width: 90%;
        max-width: 750px;

        .popup-inner{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #FFF;
        }

        .popup-buttons{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 1%;
            width: 100%;
            padding: 2%;

            .popup-button{
                 padding: .5rem .75rem;
                 color: $color-white;
                 background-color: #d41c4c;
                 border: #d41c4c 1px solid;

                 width: 50%;
                 font-size: calc(12px + 0.4vw);


                :enabled:hover {
                    background: $color-info-hover;
                    border-color: $color-info-hover;
                }
            }
        }

        .popup-header{
            width: 100%;
            color:#FFF;
            background-color: $color-primary;
            padding: 1%;
            font-size: calc(16px + 0.4vw);
        }

        .popup-text{
            padding: 2%;
            color: $color-primary;
            font-size: calc(16px + 0.8vw);
        }
    }
</style>
