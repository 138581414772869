<template>
	<h1>{{ t("damageReport.pageTitle") }}</h1>
	<div class="d-grid col-lg-4 mx-auto">
        <div v-for="button in buttons" :key="button.id">
        	<form method="post" target="_blank" :action="reportUri">
				<input type="hidden" name="form" :value="button.form" />
				<input type="hidden" name="operatorId" :value="user.personalNumber" />
				<input type="hidden" name="operatorEmail" :value="user.email" />
				<input type="hidden" name="operatorFirstName" :value="user.firstName" />
				<input type="hidden" name="operatorLastName" :value="user.lastName" />

				<Button :label="button.label" class="p-button-success" type="submit" />
			</form>
        </div>
    </div>
</template>

<script lang="ts">

	import config from '@/config';
	import { useStore } from '@/store';
	import { computed, defineComponent, onMounted } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useHead } from '@vueuse/head';
	import General from '../help/GeneralFunction';
    import WebSocketFunctions from '../help/WebSocketFunctions';
    import { WebSocketMessageTypes } from '@/types/WebSocketMessageTypes';

	export default defineComponent({
		setup() {
			const store = useStore();
            const { t } = useI18n();
			const general = new General();
            useHead({
                meta: [
                    {
                        name: `robots`,
                        content: "noindex",
                    },
                ],
            });
			
            function cutEmail(text: string | undefined): string | undefined {
                if (text) {
                    let result = text;
                    if (result.indexOf("#") > 0) {
                        if (!general.checkEmailOrderingSuffix(text)) {
                            text = text.replace("@", "");
                        }
                        result = text.substring(0, text.indexOf("#"));
                        if (general.checkEmailSuffix(text)) {
                            return result;
                        }
                    }
                    return text;
                }
                return text;
			}

            const user = computed(() => {
				const base64Url = (store.state.token?.accessToken.toString() ?? '').split('.')[1];
    			const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    			const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				}).join(''));

				return {
					id: store.state.user?.id,
					email: cutEmail(store.state.user?.email),
					firstName: store.state.user?.firstName,
					lastName: store.state.user?.lastName,
					personalNumber: JSON.parse(jsonPayload).acquisition_number
				}
			});

			const buttons = [
				{
					id: 0,
					label: t("damageReport.buttons.insured"),
					form: "MTPL_SKODCA"
				},
				{
					id: 1,
					label: t("damageReport.buttons.injured"),
					form: "MTPL_POSKODENY"
				},
				{
					id: 2,
					label: t("damageReport.buttons.carInsurance"),
					form: "CASCO"
				},
				{
					id: 3,
					label: t("damageReport.buttons.frontGlassInsurance"),
					form: "GLASS"
				},
				{
					id: 4,
					label: t("damageReport.buttons.propertyInsurance"),
					form: "PROPERTY"
				},
				{
					id: 5,
					label: t("damageReport.buttons.damageResponsibilityInsurance"),
					form: "LIABILITY"
				},
				{
					id: 6,
					label: t("damageReport.buttons.travelInsurance"),
					form: "TRAVEL"
				},
				{
					id: 7,
					label: t("damageReport.buttons.lifeInsurance"),
					form: "LIFE"
				},
			]

			return {
				user,
				buttons,
				reportUri: config.damageReportUrl,
				t
			}
		}
	});

</script>

<style scoped lang="scss">

button {
	margin-bottom: .75rem;
}

</style>
