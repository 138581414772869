import { createRouter,  createWebHistory, RouteRecordRaw } from 'vue-router';
import ChangePassword from '@/views/ChangePassword.vue';
import ResetPasswordVerify from '@/views/ResetPasswordVerify.vue';
import UserProfile from '@/views/UserProfile.vue';
import Tiles from '@/views/Tiles.vue';
import DamageReport from '@/views/DamageReport.vue';
import DamageReportSK from '@/views/DamageReportSK.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import LogOut from '@/views/LogOut.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/tiles',
	},
	{
		path: '/tiles',
		name: 'tiles',
		component: Tiles,
		meta: { background: "tiles" }
	},
	{
		path: '/user-profile',
		name: 'user-profile',
		component: UserProfile,
		meta: { background: "user-profile" }
	},
	{
		path: '/damage-report',
		name: 'damage-report',
		component: DamageReport,
		meta: { background: "damage-report" }
	},
	{
		path: '/damage-report-sk',
		name: 'damage-report-sk',
		component: DamageReportSK,
		meta: { background: "damage-report" }
	},
	{
		path: '/change-password',
		name: 'change-password',
		component: ChangePassword,	
		meta: { background: "change-password" }
	},
	{
		path: '/resetPassword',
		name: 'ResetPassword',
		component: ResetPassword,
		meta: { background: "reset-password" }
	},
	{
		path: '/reset-password-verify',
		name: 'reset-password-verify',
		component: ResetPasswordVerify,
		meta: { background: "reset-password" }
	},
	{
		path: '/logOut',
		name: 'LogOut',
		component: LogOut,
		meta: { background: "log-out" }
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
});

export default router