import axios from 'axios';
import FinishResetPasswordDto from '../dtos/FinishResetPasswordDto';
import PasswordResetResponseDto from '../dtos/PasswordResetResponseDto';
import ResetPasswordDto from '../dtos/ResetPasswordDto';
import VerifyTokenResponseDto from '../dtos/VerifyTokenResponseDto';
import PasswordResetClientMock from './mocks/PasswordResetClientMock';
import ResetPasswordValidateDto from '../dtos/ResetPasswordValidateDto';

class PasswordReset {	
	private readonly RESOURCE_PASSWORD_RESET = 'password-reset';
	
	public init(username: string): Promise<void> {
		return axios.post(`${this.RESOURCE_PASSWORD_RESET}/init/${username}`);
	}

	public async verify(data: ResetPasswordDto): Promise<PasswordResetResponseDto> {
		return (await axios.post(`${this.RESOURCE_PASSWORD_RESET}/verify`, data, { supressErrorMessage: true })).data;
	}

	public async finish(data: FinishResetPasswordDto): Promise<VerifyTokenResponseDto> {
		return (await axios.post(`${this.RESOURCE_PASSWORD_RESET}/finish`, data)).data;
	}

	public async validate(data: ResetPasswordValidateDto): Promise<void> {
		return (await axios.post(`${this.RESOURCE_PASSWORD_RESET}/validate`, data)).data;
	}
}

//export default process.env.NODE_ENV === 'production' ? PasswordReset : PasswordResetClientMock
export default PasswordReset 