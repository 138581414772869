
import {computed, defineComponent, reactive } from 'vue';
import UErrorContainer from "@/components/common/UErrorContainer.vue";
import UErrorItem from "@/components/common/UErrorItem.vue";
import { required } from '@vuelidate/validators';
import { strongPassword } from '@/validation/Validators';
import useVuelidate from '@vuelidate/core';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: { UErrorContainer, UErrorItem },
    props: {
        modelValue: String,
        label: String,
        disabled: Boolean,
        minPasswordLength: Number
    },
    emits: ['update:modelValue'],

    setup(props, { emit }) {
        const { t } = useI18n();
        const form = reactive({
            val: props.modelValue,
        })
        
        const strongPasswordMessage = computed(() => {
            return t("strongPassword.messages.strongPassword1", {length: props.minPasswordLength})});
            
        const strongPasswordValidation = () => strongPassword(form.val, props.minPasswordLength); 
        const rules = {
            val: { required, strongPasswordValidation }
        };

        const v$ = useVuelidate(rules, form);

        const onChanged = (event: string) => {
            form.val = event;
            v$.value.$touch();
            emit('update:modelValue', event);
        }

        return {
            form,
            v$,
            onChanged,
            t,
            strongPasswordMessage
        }
    },
})
