<template>
    <div class="col-md-4 mx-auto">
        <h1>{{ t("changePassword.pageTitle") }}</h1>
    </div>

    <div class="col-md-5 col-lg-4 mx-auto u-form">
        <form @submit.prevent="changePassword(!v$.$invalid)">
            <div class="p-float-label">
                <InputText type="password" 
                           id="chng-pass-orig" 
                           :placeholder="t('changePassword.fields.originalPassword')" 
                           class="p-inputtext-lg"
                           v-model="v$.origPass.$model"
                           :class="{'p-invalid': v$.origPass.$invalid && v$.origPass.$dirty}"
                           :disabled="saving || secondFactorMissing" />
                <label for="chng-pass-orig">{{ t("changePassword.fields.originalPassword") }}</label>
            </div>
            <u-error-container v-if="v$.origPass.$invalid && v$.origPass.$dirty">
                <u-error-item v-if="v$.origPass.required.$invalid">
                    {{ t("changePassword.validations.enterOriginalPassword") }}
                </u-error-item>
            </u-error-container>

            <u-strong-password v-model="form.newPass" :minPasswordLength="minPasswordLength"
                               :label="t('changePassword.fields.newPassword')"
                               :disabled="saving || secondFactorMissing" />
    
            <div class="p-float-label">
                <InputText type="password"
                           id="chng-pass-verify" 
                           :placeholder="t('changePassword.fields.verifyNewPassword')"
                           class="p-inputtext-lg"
                           v-model.trim="v$.newPassVerify.$model"
                           :class="{'p-invalid': v$.newPassVerify.$invalid && v$.newPassVerify.$dirty}"
                           :disabled="saving || secondFactorMissing" />
                <label for="chng-pass-verify">{{ t("changePassword.fields.verifyNewPassword") }}</label>
            </div>
            <u-error-container v-if="v$.newPassVerify.$invalid && v$.newPassVerify.$dirty">
                <u-error-item v-if="v$.newPassVerify.passwordsMatch.$invalid ">
                    {{ t("changePassword.validations.passwordsMismatch") }}
                </u-error-item>
                <u-error-item v-if="v$.newPassVerify.required.$invalid">
                    {{ t("changePassword.validations.enterVerifyPassword") }}
                </u-error-item>
            </u-error-container>
                <Message severity="info" :closable="false">
                    {{ t("changePassword.messages.resetPasswordMessage") }}
                    <a href="#" @click="$router.push('resetPassword')">{{t("changePassword.messages.resetPassword")}}</a>
                </Message>
            <div class="d-grid gap-2">
                <Button type="submit"
                        :label="t('changePassword.buttons.submit')"
                        class="p-button-success" 
                        :disabled="loading || saving || secondFactorMissing"
                        :icon="loadingIcon"
						iconPos="right" />
            </div>
        </form>
    </div>
     <verification-prompt v-model:visible="showVerifyPrompt" @submited="continueSaving($event)" @resendVerificationCode="resendVerificationCode()" ref="verifyPrompt"/>
      <Dialog position="center" :closable="true" :showHeader="true" :header="t('tiles.messages.warning')"
            v-model:visible="displayModal" id="idDialog">
        {{ t("changePassword.messages.missing2faError", { loginname: login}) }}
        <template #footer>
            <Button label="OK" v-on:click="displayModal = false;" />
        </template>
    </Dialog>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
    import AccountClient from '@/services/AccountClient';
    import ChangePasswordDto from '@/dtos/ChangePasswordDto';
    import SecondFactorClient from '@/services/SecondFactorClient';
    import { useToast } from "primevue/usetoast";
    import { useRouter } from 'vue-router';
    import { required, requiredIf } from '@vuelidate/validators';
    import useVuelidate from "@vuelidate/core";
    import UErrorContainer from "@/components/common/UErrorContainer.vue";
    import UErrorItem from "@/components/common/UErrorItem.vue";
    import { useStore } from '@/store';
    import UStrongPassword from '@/components/common/UStrongPassword.vue';
    import { useI18n } from 'vue-i18n';
    import { useHead } from '@vueuse/head';
    import AccountDto from '../dtos/AccountDto';
    import SecondFactorDto from '@/dtos/SecondFactorDto';
    import VerificationPrompt from '@/components/VerificationPrompt.vue';
    import ChangePasswordValidateDto from '@/dtos/ChangePasswordValidateDto';
    import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';
    import WebSocketFunctions from '../help/WebSocketFunctions';
    import { WebSocketMessageTypes } from '@/types/WebSocketMessageTypes';
    
    export default defineComponent({
        components: { UErrorContainer, UErrorItem, UStrongPassword, VerificationPrompt },
        setup() {
            const toast = useToast();
            const router = useRouter();
            const store = useStore();
            const { t } = useI18n();
            const loading = ref(false), saving = ref(false);
            const loadingIcon = computed(() => loading.value || saving.value
                ? "pi pi-spin pi-spinner" : "");
            const minPasswordLength = ref(10);
            const userId = ref(store.state.user?.id ?? "");

            const accountClient = new AccountClient();
            const account = reactive(new AccountDto());
            let secFactor: SecondFactorDto;
            const secondFactorMissing = ref(false);
            let verificationCodeSent = false;
            const verifyPrompt = ref(null);
            const showVerifyPrompt = ref(false);
            const useSecondFactorVerification = ref(false);

            const reCaptcha = useReCaptcha();
            let recaptchaToken = "";
            const login = ref('');
            const displayModal = ref(false);

            const getRecaptchaToken = async () => {
                if (!store.getters.isRecaptchaEnabled) {
                    return true;
                }
                recaptchaToken = "";
                // (optional) Wait until recaptcha has been loaded.
                if (reCaptcha) {
                    await reCaptcha.recaptchaLoaded();
                    recaptchaToken = await reCaptcha.executeRecaptcha('resetPassword');
                }

                if (recaptchaToken == null || recaptchaToken == undefined || recaptchaToken == "") {
                    toast.add({
                        severity: 'error',
                        summary: t("toast.titles.error"),
                        detail: t("toast.messages.recaptchaError")
                    });
                    return false;
                }

                return true;
            }

            const form = reactive({
                origPass: "",
                newPass: "",
                newPassVerify: ""
            });

            useHead({
                meta: [
                    {
                        name: `robots`,
                        content: "noindex",
                    },
                ],
            });

            onMounted(async () => {
                try {
                    loading.value = true;
                    Object.assign(account, await accountClient.getMe());
                    login.value = account.username ? account.username : account.email;
                    if (account.type === "Service") {
                        minPasswordLength.value = 25;
                    } else {
                        minPasswordLength.value = 10;
                    }

                    if (account.usePasswordConfirmation) {
                        useSecondFactorVerification.value = false;
                    } else {
                        useSecondFactorVerification.value = true;
                        secFactor = (await accountClient.getSecondFactors(userId.value))[0];
                        if (secFactor == null) {
                            secondFactorMissing.value = true;
                            displayModal.value = true;
                        }
                    }
                } catch {
                    // intercepted by axios
                }

                loading.value = false;
            });

            const passwordsMatch = () => form.newPass === form.newPassVerify; // TODO duplicated
            const rules = {
                origPass: { required },
                newPassVerify: { required, passwordsMatch }
            };

            const v$ = useVuelidate(rules, form);

            const authenticate = async () => {
                try {
                    if (!verificationCodeSent) {
                        await new SecondFactorClient().authenticate(secFactor.id, recaptchaToken)
                        Object(verifyPrompt.value).startTimer();
                        verificationCodeSent = true;
                    }
                    showVerifyPrompt.value = true;
                } catch {
                    // intercepted by axios
                }
            };

            const resendVerificationCode = async () => {
                if (!await getRecaptchaToken()) {
                    return;
                }
                verificationCodeSent = false;
                await authenticate();
            };

            const continueSaving = async (verificationData: string) => {
                saving.value = true;

                try {
                    await accountClient.changePasswordVerify(
                        userId.value,
                        new ChangePasswordDto({
                            originalPassword: form.origPass,
                            newPassword: form.newPass,
                            verificationToken: verificationData
                        }));

                    if (useSecondFactorVerification.value) {
                        showVerifyPrompt.value = false;
                    }

                    toast.add({
                        severity: 'success',
                        detail: t("toast.messages.passwordChangeSuccess"),
                        life: 3000
                    });

                    router.push("user-profile");

                } catch {
                    // intercepted by axios
                }

                saving.value = false;

            };
            const changePassword = async (valid: boolean) => {

                v$.value.$touch();
                if (!valid) {
                    return;
                }

                saving.value = true;
                try {
                    if (useSecondFactorVerification.value) {
                        try {
                            await accountClient.validateMyNewPassword(new ChangePasswordValidateDto({
                                originalPassword: form.origPass,
                                newPassword: form.newPass
                            }));

                            if (!await getRecaptchaToken()) {
                                return;
                            }
                            await authenticate();
                        }
                        catch {
                            // intercepted by axios
                        }
                    } else {
                        continueSaving("");
                    }

                } catch {
                    // intercepted by axios
                }

                saving.value = false;
            };
            return {
                form,
                v$,
                loading,
                saving,
                loadingIcon,
                changePassword,
                t,
                secondFactorMissing,
                showVerifyPrompt,
                verifyPrompt,
                continueSaving,
                resendVerificationCode,
                minPasswordLength,
                login,
                displayModal
            };
        },
    })
</script>