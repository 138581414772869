<template>
	<template v-if="emailSent">
		<h1>{{ t("resetPassword.pageTitles.emailSent")}}</h1>
		<div class="col-md-5 col-lg-4 mx-auto u-text-content">
			<p>
				{{ t( "resetPassword.messages.emailSent1") }}
				{{form.username}}
				{{ t( "resetPassword.messages.emailSent2") }}
			</p>
		</div>
	</template>
	<template v-else>
		<div class="row">
			<div class="col-md-6 mx-auto">
				<h1>{{passwordExpiredMessage}}</h1>
				<h1>{{ t("resetPassword.pageTitles.enterUsername")}}</h1>
			</div>
		</div>
	
		<div class="row">
			<div class="col-md-5 col-lg-4 mx-auto u-form">
				<div class="p-float-label">
					<InputText v-model.trim="form.username"
							   class="p-inputtext-lg"
							   id="pass-reset-email"
							   :placeholder="t('resetPassword.fields.userName')"
							   :disabled="loading" 
							   @keyup.enter="startPasswordReset"
							   />
							   
					<label for="pass-reset-email">{{ t("resetPassword.fields.userName")}}</label>
					<br />
				</div>
	
				<div class="d-grid gap-2">
					<Button :label="t('resetPassword.buttons.submit')" 
							class="p-button-success" 
							:disabled="loading || !form.username"
							:icon="loadingIcon" 
							iconPos="right"
							@click="startPasswordReset" />
				</div>
			</div>
		</div>
	</template>
</template>

<script lang="ts">

import { computed, defineComponent, reactive, ref } from 'vue';	
import PasswordResetClient from '../services/PasswordResetClient';
import { useI18n } from 'vue-i18n';
import router from '../router';
import { useHead } from '@vueuse/head';

export default defineComponent({   
	setup() {
		const { t } = useI18n();
		const client = new PasswordResetClient();

		const loading = ref(false), emailSent = ref(false);
		const loadingIcon = computed(() => loading.value ? "pi pi-spin pi-spinner" : "");
		const form = reactive({
			username: ""
		});
		const passwordExpiredMessage = ref('');

        useHead({
            meta: [
                {
                    name: `robots`,
                    content: "noindex",
                },
            ],
        });

		const startPasswordReset = async () => {
			if (loading.value || !form.username) {
				return;
			}

			loading.value = true;
			try {
				const username = encodeURIComponent(form.username);
				const res = await client.init(username);
				emailSent.value = true;
			} catch {
				// intercepted by axios
			}

			loading.value = false;
		};

		if(router.currentRoute.value.query.expired !== undefined)
		{
			passwordExpiredMessage.value = t("resetPassword.pageTitles.passwordExpired");	
		}

		return {
			form,
			emailSent,
			loading,
			loadingIcon,
			t,
			startPasswordReset,
			passwordExpiredMessage
		}			
	}
});

</script>