<template>
	<h1>{{ t("logOut.titles.logOut")}}</h1>
</template>

<script lang="ts">

import { defineComponent } from 'vue';	
import { useI18n } from 'vue-i18n';
import { useHead } from '@vueuse/head';

export default defineComponent({   
	setup() {
		const { t } = useI18n();
        useHead({
            meta: [
                {
                    name: `robots`,
                    content: "noindex",
                },
            ],
        });

		return {	
			t
		}			
	}
});

</script>