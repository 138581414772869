export default {
    apiUrl: "https://localhost:44365/api",
    //authUrl: "https://login-dev.uniqa.cz/auth/realms/people/protocol/openid-connect",
    authUrl: "https://login-test.uniqa.cz/auth/realms/people/protocol/openid-connect",
    authRedirectUri: "http://localhost:8080",
    damageReportUrl: "https://skody-t.uniqa.cz/form/redirect/",
    damageReportUrlBaseSK: "https://skody-t.uniqa.sk/",
    wsUrl: "wss://wsocket-test.uniqa.cz/messaging",
    envName: "-Local" 
}
