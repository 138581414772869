import axios from 'axios';
import BrokerDto from '../dtos/BrokerDto';
import BrokerClientMock from './mocks/BrokerClientMock';
import BrokerServiceMock from './mocks/BrokerClientMock';

class BrokerClient  {
	protected name = 'Brokers';

	public async getById(id: string): Promise<BrokerDto> {
		return (await axios.get(`${this.name}/${id}`)).data;
	}
}

//export default process.env.NODE_ENV === 'production' ? BrokerClient : BrokerClientMock
export default BrokerClient
