import axios from 'axios';
import CreateAuditDto from '../dtos/CreateAuditDto';

class AuditClient {
	private readonly RESOURCE_AUDIT = 'Audit';

	public async createAudit(data: CreateAuditDto): Promise<boolean> {
		return await (await axios.post(`${this.RESOURCE_AUDIT}`, data, { supressErrorMessage: true })).data;
	}
}

export default AuditClient