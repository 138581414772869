
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n';
import store from '../store';
import { version } from '../../package.json';

export default defineComponent({
    setup() {
        const { t } = useI18n();
        return { t, store, appVersion:version };
    },
})
