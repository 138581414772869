<template>
	<h1>{{ t("tiles.pageTitle") }}</h1>
    <h2 class="special-sorting-caption" v-if="sortModeEnabled" style="text-align: center;">{{  t("tiles.pageSortSubTitle") }}</h2> 
    <div v-if="loading" class="col square">
        <div v-for="i in [...Array(5).keys()]" :key="i">
            <skeleton height="100%" />
        </div>
	</div>
    <div v-else>
        <draggable v-if="sortModeEnabled" tag="div" :list="tiles" class="tiles-container" @end="increaseRerenderKey">
            <template #item="{element}">
                <div :key="element.applicationName">
                    <u-tile class="special-sorting-mod" :block-link="true" :tileData="element" :key="reRenderKey"/>
                </div>
            </template>
	    </draggable>
        <div v-else :list="tiles" class="tiles-container">
            <div v-for="tile in tiles" v-bind:key="tile.applicationName">
                <u-tile :block-link="false" :tileData="tile"/>
            </div>
	    </div>
    </div>
    <div class="special-sorting-button-container">
        <Button class="p-button-sort-red flex-col" v-if="sortModeEnabled" :label="t('tiles.buttons.save')" @click="saveSequenceToDB()"/>
        <Button class="p-button-sort-blue flex-col" v-if="sortModeEnabled" :label="t('tiles.buttons.back')"  @click="returnInitialArrayState()"/>
    </div>
	
	<Dialog position="center" :closable="true" :showHeader="true" :header="t('tiles.messages.warning')" :style="{width: '50vw'}"
			v-model:visible="displayModal" id="idDialog">
		{{checkPhoneMessage}}
		<template #footer>
			<Button :label="dialogUserProfileButtonLabel()" @click="$router.push('user-profile')" v-on:click="displayModal = false;" />
			<Button v-if="showPhoneNumberIsCorrectButton()" :label="t('tiles.buttons.phoneNumberIsCorrect')" v-on:click="confirmPhoneNumber()" />
		</template>
	</Dialog>

    <Dialog :closable="false" v-model:visible="hasHCLRightAndEmptySecondaryEmail" modal :header="t('tiles.messages.warning')" :style="{ width: '30vw' }">
        <span class="h5 text-dark">{{t('tiles.messages.fillSecondaryEmail')}}</span>
        <template #footer>
            <Button type="button" :label="t('tiles.buttons.set')" @click="$router.push('user-profile')"></Button>
            <Button :hidden="continueDialogButtonIsHidden" type="button" :label="t('tiles.buttons.continue')" severity="secondary" @click="secondaryEmailDialogVisible = false"></Button>
        </template>
    </Dialog>
</template>

<script lang="ts">

import { defineComponent, onMounted, reactive, ref, h } from 'vue';
import TilesClient from '../services/TilesClient';
import UTile from '@/components/common/UTile.vue';
import { useI18n } from 'vue-i18n';
import store, { useStore } from '@/store';
import { MutationTypes } from '../store/MutationTypes';
import moment from 'moment';
import ExtraPropertyClient from '@/services/ExtraPropertyClient';
import ExtraPropertyDto from '../dtos/ExtraPropertyDto';
import { useHead } from '@vueuse/head';
import { useDialog } from "primevue/usedialog";
import PasswordExpirationDialog from '@/components/PasswordExpirationDialog.vue';
import Button from 'primevue/button';
import { useRouter } from 'vue-router';
import WebSocketFunctions from '../help/WebSocketFunctions';
import { WebSocketMessageTypes } from '@/types/WebSocketMessageTypes';
import TileCmsDto from '@/dtos/TileCmsDto';
import TilesSequenceDto from '@/dtos/TilesSequenceDto';
import draggable from 'vuedraggable';
import EventBus from '@/help/EventBus';

export default defineComponent({
    name: 'Tile',
    components: { UTile, draggable },
    setup() {
        const tilesClient = new TilesClient();
        const store = useStore();
        const tiles = reactive(Array<TileCmsDto>());
        const initialTiles = reactive(Array<TileCmsDto>());
        const dialog = useDialog();
        const router = useRouter();

        const loading = ref(false);
        const displayModal = ref(false);
        const checkPhoneMessage = ref('');
        const { t } = useI18n();
        const extraPropertyClient = new ExtraPropertyClient();
        const phoneNumberValidated = "PhoneNumberValidated";
        const extraName = "triton";
        const user = store.state.user;
        const secondaryEmailDialogVisible = ref(true);
        const reRenderKey = ref(0);
        const sortModeEnabled = ref(false);

        useHead({
            meta: [
                {
                    name: `robots`,
                    content: 'index',
                },
            ],
        });

        function getNumberOfDaysToSecondFactor(secondFactorActivationDate: Date): number {
            const diff = Math.abs(secondFactorActivationDate.getTime() - new Date().getTime());
            const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
            return diffDays;
        }

        onMounted(async () => {
            loading.value = true;
            try {
                Object.assign(tiles, await tilesClient.getAll());
                Object.assign(initialTiles, tiles);
            } catch {
                // intercepted by axios
            }

            EventBus.on('sortModeEnabled', async () =>
            {
                sortModeEnabled.value = true;
            });

            if (user && user.secondFactorActivationDate) {
                const numberOfDaysToSecondFactor = user.secondFactorActivationDate == undefined ? 0 : getNumberOfDaysToSecondFactor(user.secondFactorActivationDate)
                const phoneNumberIsEditableUntil = user.secondFactorActivationDate;
                phoneNumberIsEditableUntil.setDate(phoneNumberIsEditableUntil.getDate() - 1);

                if (user.phoneNumberIsEditable && store.state.showCheckPhoneDialog) {
                    try {
                        const respone = await extraPropertyClient.getMe(phoneNumberValidated, extraName, true);
                        if (respone.length == 0) {
                            checkPhoneMessage.value =
                                t("tiles.messages.daysToSecondFactor", { daysCount: numberOfDaysToSecondFactor }) + " " +
                                ((user.phoneNumber == undefined || user.phoneNumber == "") ?
                                    t("tiles.messages.enterPhone", { date: moment(phoneNumberIsEditableUntil).format('D.M.YYYY') }) :
                                    t("tiles.messages.checkPhone", { phoneNumber: user.phoneNumber, date: moment(phoneNumberIsEditableUntil).format('D.M.YYYY') }));
                            displayModal.value = true;
                        }
                        store.commit(MutationTypes.setShowCheckPhoneDialog, false);
                    } catch {
                        // intercepted by axios
                    }
                }
            }

            if (user && user.showPasswordExpirationWarning && store.state.showPasswordExpirationDialog) {
                const dialogRef = dialog.open(PasswordExpirationDialog, {
                    props: {
                        header: t("tiles.messages.errorPasswordExpirationTitle"),
                        modal: true,
                        dismissableMask: false,
                        closable: false
                    },
                    templates: {
                        footer: () => {
                            return [
                                h(Button, {
                                    label: t("tiles.buttons.changePassword"),
                                    onClick: () => {
                                        router.push('change-password');
                                        dialogRef.close();
                                    }, autofocus: true
                                }),
                                h(Button, {
                                    label: 'OK',
                                    onClick: () => {
                                       dialogRef.close();
                                    }, autofocus: false
                                })
                            ]
                        }
                    }
                });
                store.commit(MutationTypes.showPasswordExpirationDialog, false);
            }
            
            loading.value = false;
        });

        const confirmPhoneNumber = async () => {
            //const user = store.state.user;
            displayModal.value = false;
            if (user) {
                try {
                    const extraPropertyDto = new ExtraPropertyDto();
                    extraPropertyDto.userId = String(user.id);
                    extraPropertyDto.name = phoneNumberValidated;
                    extraPropertyDto.extraName = extraName;
                    extraPropertyDto.content = moment(new Date()).format('DD.MM.YYYY HH:mm:ss');

                    await extraPropertyClient.create(extraPropertyDto);
                } catch {
                    // intercepted by axios
                }
            }
        };

        const showPhoneNumberIsCorrectButton = () => {
            return user && user.phoneNumber != undefined && user.phoneNumber != "";
        };

        const dialogUserProfileButtonLabel = () => {
            if (showPhoneNumberIsCorrectButton()) {
                return t('tiles.buttons.changePhoneNumber')
            }
            return t('tiles.buttons.enterPhoneNumber')
        };

        return { tiles, 
                 initialTiles,
                 loading, 
                 t, 
                 displayModal, 
                 checkPhoneMessage, 
                 confirmPhoneNumber, 
                 dialogUserProfileButtonLabel, 
                 showPhoneNumberIsCorrectButton, 
                 secondaryEmailDialogVisible, 
                 user,
                 reRenderKey,
                 tilesClient,
                 sortModeEnabled };
    },

    computed:{
        hasHCLRightAndEmptySecondaryEmail(): boolean{
            return this.tiles.some(x => x.applicationName == 'HCL') && (this.user?.secondaryEmail === null || this.user?.secondaryEmail === undefined) && this.secondaryEmailDialogVisible;
        },

        continueDialogButtonIsHidden(): boolean{
            return new Date() > new Date(store.state.app.secondaryEmailDialogDate);
        }
    },

    methods:{
        async saveSequenceToDB(){
            this.increaseRerenderKey();
            const tilesSequenceData = JSON.stringify(this.tiles.map(x => x.applicationName));
            const tilesSubsequenceDto = new TilesSequenceDto();
            tilesSubsequenceDto.data = tilesSequenceData;
            await this.tilesClient.SaveTilesSequence(tilesSubsequenceDto);

            this.sortModeEnabled = false;
        },

        returnInitialArrayState(){
            Object.assign(this.tiles, this.initialTiles);
            this.increaseRerenderKey();
            this.sortModeEnabled = false;
        },

        increaseRerenderKey(){
            this.reRenderKey += 1;
        }
    }
});

</script>