import { WebSocketMessageTypes } from "@/types/WebSocketMessageTypes";

export default class Message {
    public id: string;
    public messageType: WebSocketMessageTypes;

  constructor(id: string, messageType: WebSocketMessageTypes) {
    this.id = id
    this.messageType = messageType
  }
}