
import { defineComponent, ref } from 'vue'
import UIcon from '@/components/common/UIcon.vue'
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: { UIcon },
    props: {
        image: String,
        showTimer: Boolean, 
        tooltip: String
    },
    setup(props){
        const { t } = useI18n();
      
        const timer = ref(null);
        function resetCounter (){
            if(props.showTimer){
                Object(timer.value).resetCounter();
            }
        }
        return {resetCounter, timer}
    }
})
