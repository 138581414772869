
import { defineComponent, ref } from 'vue'
    import UIdleTimer from '@/components/common/UIdleTimer.vue';

export default defineComponent({
    props: {
        image: String,
        showTimer: Boolean
    },
    components:{UIdleTimer},
    async setup(props) {
        const imgPath = await import(`@/assets/${props.image}.svg`);
        const imgClass = props.showTimer ? "img-timer" : "";
        const timer = ref(null);
        function resetCounter (){
            if(props.showTimer){
                Object(timer.value).resetCounter();
            }
        }
        return { imgPath: imgPath.default, imgClass, resetCounter, timer };
    },
})
