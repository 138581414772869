

    import { defineComponent, ref } from 'vue'
    import { useI18n } from 'vue-i18n';
    import { useStore } from '@/store';


    export default defineComponent({
        setup() {
            const { t } = useI18n();
            const store = useStore();
            const user = store.state.user;
            const passwordExpirationDate = ref('');
            if (user && user.passwordExpirationDate) {
                passwordExpirationDate.value = new Date(user.passwordExpirationDate).toLocaleDateString();
            }
            return {
                t,
                passwordExpirationDate
            };
        }
    })

